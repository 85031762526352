import React from 'react';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useStoreState } from 'easy-peasy';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProjectStatusCard = () => {
    const toast = useRef(null);
    const projectDashboardData = useStoreState((actions) => actions.tabModel.projectDashboardData);

    return (
        <>
            <Toast ref={toast} />
            <div className='my-3 grid'>
                <div className='col-12 md:col-6 lg:col-4'>
                    <div className='projects_status-cards_blue p-4'>
                        <div className="flex justify-content-center">
                            <div className="w-full md:w-4 cpb_blue">
                                <CircularProgressbar value={projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.openCount / projectDashboardData?.sumOfSubTasksCount) * 100)} text={`${projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.openCount / projectDashboardData?.sumOfSubTasksCount) * 100)}%`} />
                            </div>
                        </div>
                        <div className='flex justify-content-between mt-3 project-staus-value-color-blue'>
                            <h5 className='projects-status-title mb-0'>Open</h5>
                            <p className='projects-status-value'>{projectDashboardData?.openCount} <span className='projects-status-value-out_of'>/ {projectDashboardData?.sumOfSubTasksCount}</span></p>
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-6 lg:col-4'>
                    <div className='projects_status-cards_purple p-4'>
                        <div className="flex justify-content-center">
                            <div className="w-full md:w-4 cpb_purple">
                                <CircularProgressbar value={projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.inProgressCount / projectDashboardData?.sumOfSubTasksCount) * 100)} text={`${projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.inProgressCount / projectDashboardData?.sumOfSubTasksCount) * 100)}%`} />
                            </div>
                        </div>
                        <div className='flex justify-content-between mt-3 project-staus-value-color-purple'>
                            <h5 className='projects-status-overall-title mb-0'>Inprogress</h5>
                            <p className='projects-status-value'>{projectDashboardData?.inProgressCount} <span className='projects-status-value-out_of'>/ {projectDashboardData?.sumOfSubTasksCount}</span></p>
                        </div>
                    </div></div>
                <div className='col-12 md:col-6 lg:col-4'>
                    <div className='projects_status-cards_green p-4'>
                        <div className="flex justify-content-center">
                            <div className="w-full md:w-4 cpb_green">
                                <CircularProgressbar value={projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.completedCount / projectDashboardData?.sumOfSubTasksCount) * 100)} text={`${projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.completedCount / projectDashboardData?.sumOfSubTasksCount) * 100)}%`} />
                            </div>
                        </div>
                        <div className='flex justify-content-between mt-3 project-staus-value-color-green'>
                            <h5 className='projects-status-overall-title mb-0'>Completed</h5>
                            <p className='projects-status-value'>{projectDashboardData?.completedCount} <span className='projects-status-value-out_of'>/ {projectDashboardData?.completedCount}</span></p>
                        </div>
                    </div></div>
            </div>
        </>
    );
}

export default ProjectStatusCard;