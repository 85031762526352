import { useForm } from "react-hook-form";
import "./login.css";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import clanizon from "./Images/clanizon.png";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import axios from "axios";
import constants from "./constants/constants";
import { Toast } from "primereact/toast";
import { useStoreActions } from "easy-peasy";

const Forgetpwd = () => {
    const toast = useRef(null);
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const setEmailVerificationResponse = useStoreActions((actions) => actions.loginModel.setEmailVerificationResponse);

    useEffect(() => {
        setEmailVerificationResponse();
    }, []);

    const onSubmit = (data) => {
        const payload = {
            email: data.userEmail,
        };
        setIsLoading(true);
        axios.post(constants.URL.EMAIL_VERIFICATION, payload)
            .then((resp) => {
                setEmailVerificationResponse(resp.data.results);
                history.replace("/resetPassword")
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    };

    const gotoSignIn = (to) => {
        history.replace(to);
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="login_container">
                <div className="md:col-5 hidden md:flex p-0" style={{ height: "100%" }}>
                    <img src={clanizon} alt="clanizon" className="clanizonlogo" />
                </div>

                <div className="col-12 md:col-7 flex align-items-center justify-content-center">
                    <div className=" p-4 w-full lg:w-6">
                        <div className="text-left mb-5">
                            <div className="text-900 text-3xl font-medium mb-3">Forgot Password ?</div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
                            <div>
                                <label htmlFor="email1" className="block text-900 font-medium mb-2">
                                    Email
                                </label>
                                <InputText type="text" defaultValue={""}
                                    className={`w-full mb-3 ${errors.userEmail ? 'p-invalid' : ''}`} 
                                    {...register("userEmail", {
                                      required: "This field is required",
                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address",
                                      },
                                    })}
                                  />
                                  {errors.userEmail && <p className="p-error">{errors.userEmail.message}</p>}
                            </div>
                            <Button label="Reset Password" className="w-full mt-3" />
                        </form>
                        <div className="text-blue-500 text-center mt-2 font-medium line-height-3 cursor-pointer" onClick={() => gotoSignIn("/")}>
                            Back to login
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forgetpwd;
