// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
    box-shadow: inset 0 0 0 0rem #C7D2FE;
}
.p-datepicker-trigger {
color: black;
border: 1px solid #ced4da;
background: white;
padding: 0.75rem 1.25rem;
font-size: 1rem;
transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
border-radius: 6px;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
    text-wrap: nowrap;
}

.punch-table .p-datatable .p-datatable-tbody > tr > td{
    text-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/style/override.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;AACA;AACA,YAAY;AACZ,yBAAyB;AACzB,iBAAiB;AACjB,wBAAwB;AACxB,eAAe;AACf,iFAAiF;AACjF,kBAAkB;AAClB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{\n    box-shadow: inset 0 0 0 0rem #C7D2FE;\n}\n.p-datepicker-trigger {\ncolor: black;\nborder: 1px solid #ced4da;\nbackground: white;\npadding: 0.75rem 1.25rem;\nfont-size: 1rem;\ntransition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;\nborder-radius: 6px;\n}\n.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{\n    text-wrap: nowrap;\n}\n\n.punch-table .p-datatable .p-datatable-tbody > tr > td{\n    text-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
