import { action, thunk, persist} from "easy-peasy";
import constants from "../constants/constants";
import axios from "axios";


const employeeModel = persist({
  activeIndex: 0,
  employeeDetails: [],
  employeeEditValue: null,
  
  imglist: [],
  addtoImgList: action((state, payload) => {
    state.imglist.push(payload);
  }),
  clearImgList: action((state) => {
    state.imglist = [];
  }),


  setActiveIndex: action((state, payload) => {
    state.activeIndex = payload;
  }),
  
  setemployeeList: action((state, payload) => {
    localStorage.setItem("res", JSON.stringify(payload));
    var storedNames = JSON.parse(localStorage.getItem("res"));
    state.employeeDetails = storedNames;
  }),
  
  setEmployeeEditValue: action((state, payload) => {
    state.employeeEditValue = payload;
  }),
  
});

export default employeeModel;