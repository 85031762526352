import { action, persist} from "easy-peasy";


const payslipModel = {
  payslipDetails: null,
  
  setpayslipList: action((state, payload) => {
    state.payslipDetails = payload;
  }),

};

export default payslipModel;