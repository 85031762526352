import React, { useEffect, useRef, useState } from "react";
import "../style/Pay.css";
import { useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";
import constants from "../constants/constants";
import getHeaders from "../constants/utils";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import html2pdf from 'html2pdf.js';
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";

const UserPayslip = () => {
    const toast = useRef(null);
    const [monthList, setMonthList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const user = useStoreState((state) => state.loginModel.user);
    const setpayslipList = useStoreActions((actions) => actions.payslipModel.setpayslipList);
    const payslipDetails = useStoreState((actions) => actions.payslipModel.payslipDetails);
    const [date, setDate] = useState(null);

    useEffect(async () => {
        setpayslipList(null);
        getPayslipDropdownList();
    }, []);

    useEffect(async () => {
        if (date) {
            getPayslip(date);
        }
    }, [date]);

    const getPayslipDropdownList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.PAYSLIP_DROPDOWN_LIST + user?._id, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setMonthList(resp.data.results);
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getPayslip = (dates) => {
        setIsLoading(true);
        axios
            .get(constants.URL.PAYSLIP_RELEASE + "/" + dates + "/" + user?._id, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setpayslipList(resp.data.results);
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e.response.data.message });
                console.error(e)
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    function convertToWords(num) {
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
        const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

        if (num === 0) {
            return "Zero";
        }

        if (num < 0) {
            return `Negative ${convertToWords(Math.abs(num))}`;
        }

        function convertInteger(num) {
            let words = "";

            if (num >= 1000) {
                words += `${convertInteger(Math.floor(num / 1000))} Thousand `;
                num %= 1000;
            }

            if (num >= 100) {
                words += `${ones[Math.floor(num / 100)]} Hundred `;
                num %= 100;
            }

            if (num >= 20) {
                words += `${tens[Math.floor(num / 10)]} `;
                num %= 10;
            } else if (num >= 10) {
                words += `${teens[num - 10]} `;
                num = 0;
            }

            if (num > 0) {
                words += `${ones[num]} `;
            }

            return words.trim();
        }

        // Split into integer and fractional parts
        const parts = num.toString().split(".");
        const integerPart = parseInt(parts[0], 10);
        const fractionalPart = parts[1] ? parseInt(parts[1].padEnd(2, '0'), 10) : 0;

        let words = `${convertInteger(integerPart)} Rupees`;

        if (fractionalPart > 0) {
            words += ` and ${convertInteger(fractionalPart)} Paise`;
        }

        return words.trim();
    }

    const generatePDF = () => {
        const element = document.getElementById('payslip-content');
        const opt = {
            margin: [0.3, 0.3, 0.3, 0.3],
            filename: 'Payslip_'+date+'.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    };

    const formatDate = (value) => {
        const date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    return (
        <div className="grid">
            <Toast ref={toast} />
            <div className="col-12">
                <div className="card p-fluid">
                    <div className="flex align-items-center justify-content-between">
                        <h6 className="blue_bg_heading mb-0">Payslip</h6>
                        <div className="inline-flex align-items-center">
                            <Dropdown options={monthList} value={date} onChange={(e) => setDate(e.value)} view="month" dateFormat="M-yy" className="md:w-10rem" placeholder="Month filter" />
                            <i className="pi pi-download ml-3" onClick={generatePDF} />
                        </div>
                    </div>
                    <Divider />

                    {payslipDetails ? (
                        <div id="payslip-content">
                            <h3 className="payslip_org_name">{user.org_id.name}</h3>
                            <p className="payslip_org_ad">{user.org_id.address}</p>
                            <div className="payslip_heading py-3">Pay Statement for the month of {date}
                            </div>
                            <div className="pay_sum_text mt-3">
                                Employee Pay Summary
                            </div>
                            <Divider />
                            <div className="flex flex-column sm:flex-row align-items-center">
                                <div className="col-12 sm:col-8 p-0">
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Employee Name</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.first_name}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Employee ID</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.emp_no}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Date of joining</div>
                                        <span className="payslip_value">{formatDate(payslipDetails?.user_id?.joining_date)}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Designation</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.designation}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Department</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.department}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Pay Period</div>
                                        <span className="payslip_value">{date}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Pay Date</div>
                                        <span className="payslip_value">{formatDate(payslipDetails?.payDate)}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Bank Name</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.bank_info?.name}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Bank Number</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.bank_info?.acc_no}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">PAN NO</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.pan_no}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">PF UAN</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.pf_uan}</span>
                                    </div>
                                </div>
                                <div className="col-12 sm:col-4 p-0">
                                    <div className="card sm:w-max w-full flex flex-column align-items-center custom-card sm:justify-content-left justify-content-center">
                                        <h5 className="pay_sum_text">Employee Net Pay</h5>
                                        <p className="pay_amount">&#x20B9; {payslipDetails?.netPay}</p>
                                        <p>Paid Days: {payslipDetails?.daysInMonth} | LOP Days: {payslipDetails?.lopDays}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="CardContent flex flex-column md:flex-row justify-content-between">
                                <div className="col-12 md:col-6">
                                    <div className="">
                                        <h5 className="card-head text-center mb-0 py-3">Earnings</h5>

                                        <div className="payslip_earnings_card p-0 m-0">
                                            {payslipDetails &&
                                                payslipDetails?.earnings?.map((item) => {
                                                    return (
                                                        <div className="list-content flex flex-row align-items-center justify-content-between p-2">
                                                            <div className="payslip_label">{item.title}</div>
                                                            <span className="payslip_value">{item.amount}</span>
                                                        </div>
                                                    );
                                                })}
                                            <Divider className="my-0" />
                                            <div className="flex flex-row align-items-center justify-content-between p-2">
                                                <div className="payslip_label">Gross Earnings</div>
                                                <span className=" payslip_value">&#x20B9; {payslipDetails?.totalEarnings}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="">
                                        <h5 className="card-head text-center py-3 mb-0">Deductions</h5>

                                        <div className="payslip_earnings_card p-0 m-0">
                                            {payslipDetails &&
                                                payslipDetails?.deductions?.map((item) => {
                                                    return (
                                                        <div className="list-content flex flex-row align-items-center justify-content-between p-2">
                                                            <div className="payslip_label">{item.title}</div>
                                                            <span className=" payslip_value">{item.amount}</span>
                                                        </div>
                                                    );
                                                })}
                                            <Divider className="my-0" />
                                            <div className="flex flex-row align-items-center justify-content-between p-2">
                                                <div className="payslip_label">Total Deductions</div>
                                                <span className=" payslip_value">&#x20B9; {payslipDetails?.totalDeductions}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center my-3">
                                <span className="pay-footer">
                                    <span className="pay-footer-bold">Net Pay for the month</span> (Gross Earnings – Total Deductions): <span className="pay-footer-bold">&#x20B9; {payslipDetails?.netPay}/- ({convertToWords(payslipDetails?.netPay)})</span>
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div>No results found</div>
                    )}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserPayslip, comparisonFn);
