import React, { useEffect } from "react";
import { TabMenu } from "primereact/tabmenu";
import { useStoreActions, useStoreState } from "easy-peasy";
import LeaveApproval from "./LeaveApproval";
import Leave from "../pages/Leave";
import ConpensationApproval from "./ConpensationApproval";
import LeaveDashboard from "./LeaveDashboard";
import LeaveReport from "./LeaveReport";

const LeaveTab = () => {
    const user = useStoreState((state) => state.loginModel.user);
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    const userLeaveItems = [{ label: "Dashboard" }, { label: "Leave Apply" }];

    const managerLeaveItems = [{ label: "Dashboard" }, { label: "Leave Apply" }, { label: "Leave Approval" }, { label: "Compensation Approval" }];

    const leaveReportItem = user?.permissions?.includes("PAYSLIP_ADMIN") ? [{ label: "Leave Report" }] : [];

    const tabItems = user?.isManager ? [...managerLeaveItems, ...leaveReportItem] : [...userLeaveItems, ...leaveReportItem];

    return (
        <>
            <div className="card">
                <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                {activeIndex === 0 && <LeaveDashboard />}
                {activeIndex === 1 && <Leave />}
                {activeIndex === 2 && user?.isManager && <LeaveApproval />}
                {activeIndex === 3 && user?.isManager && <ConpensationApproval />}
                {user?.permissions?.includes("PAYSLIP_ADMIN") && activeIndex === tabItems.length - 1 && <LeaveReport />} {/* Only render if user has permission */}
            </div>
        </>
    );
};

export default LeaveTab;
