import React from "react";
import { Route, Redirect } from "react-router-dom";

const CustomRoute = ({ component: Component, allowedRoles, currentUserRoles, ...rest }) => {
  const isAuthorized = currentUserRoles?.some(role => allowedRoles.includes(role));
  return (
    <Route {...rest} render={(props) => (
      isAuthorized
        ? <Component {...props} />
        : <Redirect to="/" />
    )} />
  );
};

export default CustomRoute;
