import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constants from '../../constants/constants';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import back from "../../Images/projects/black.svg";
import getHeaders from '../../constants/utils';
import { Controller, useForm } from "react-hook-form";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import TaskView from './TaskView';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from 'primereact/checkbox';
import ProjectStatusCard from './ProjectStatusCard';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';

const ProjectView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [addVisible, setAddVisible] = useState(false);
    const [headerList, setHeaderList] = useState();
    const [records, setRecords] = useState();
    const [subTaskList, setSubTaskList] = useState();
    const [tochildProjectHeader, setTochildProjectHeader] = useState(null);
    const [tochildProjectHeaderSubTask, setTochildProjectHeaderSubTask] = useState(null);
    const projectViewDatas = useStoreState((actions) => actions.projectsModel.projectViewDatas);
    const setProjectViewScreen = useStoreActions((actions) => actions.projectsModel.setProjectViewScreen);
    const setProjectDashboardData = useStoreActions((actions) => actions.tabModel.setProjectDashboardData);
    const [taskView, setTaskView] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        getProjectSubTaskByProjectId();
        getProjectTaskHeader();
        if (projectViewDatas) {
            let result = records?.find((item => item._id === projectViewDatas?._id));
            console.log(result);
            setSubTaskList(result?.taskHeader_id);
        }
        getProjectDashboard();
    }, [projectViewDatas]);

    useEffect(() => {
        if (records && records.length > 0) {
            let result = records?.find((item => item._id === projectViewDatas?._id));
            console.log(result);
            setSubTaskList(result?.taskHeader_id);
        }
    }, [records]);

    const getProjectDashboard = () => {
        axios
            .get(constants.APQP_UPL.PROJECTS_DASHBOARD + "/" + projectViewDatas?._id, { headers: getHeaders() })
            .then((resp) => {
                setProjectDashboardData(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getProjectTaskHeader = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.TASK_HEADER, { headers: getHeaders() })
            .then((resp) => {
                setHeaderList(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getProjectSubTaskByProjectId = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.PROJECTS, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const defaultValues = { title: "", desc: "", expected_edate: "" };
    const form = useForm({ defaultValues });
    const { reset } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const format = (value) => {
        var date = new Date(value);
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const handleTaskDelete = (headerId, subTaskId) => {
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id + "/taskHeader/" + headerId + "/subtask/" + subTaskId?._id, { headers: getHeaders() })
            .then((resp) => {
                getProjectSubTaskByProjectId();
                getProjectDashboard();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item, tasks) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item, tasks),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const statusBodyTemplate = (status) => {
        let statusStyle;
        switch (status) {
            case 'Completed':
                statusStyle = { color: '#659000', background: '#ECFCC0', display: "inline" };
                break;
            case 'In-Progress':
                statusStyle = { color: '#9E00C6', background: '#F9E1FF', display: "inline" };
                break;
            case "Open":
                statusStyle = { color: '#493BD7', background: '#E2DFFF', display: "inline" };
                break;
            default:
                statusStyle = { color: 'black', background: '' };
        }

        return <div style={statusStyle} className='task-status'>{status ? status : "Open"}</div>;
    };

    const HideEdit = () => {
        setAddVisible(false);
        reset();
    }

    const onSubmit = () => {
        const payload = {
            taskHeader_id: selectedCategories?.map((item) => { return item._id }),
        }
        setIsLoading(true);
        axios.patch(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id, payload, { headers: getHeaders() })
            .then((resp) => {
                toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully" });
                reset();
                setAddVisible(false);
                getProjectSubTaskByProjectId();
                getProjectDashboard();
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.error });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [preDefinedTaskdropdownValue, setPreDefinedTaskdropdownValue] = useState();

    const onCategoryChange = (e) => {
        let _selectedCategories = [...selectedCategories];

        if (e.checked)
            _selectedCategories.push(e.value);
        else
            _selectedCategories = _selectedCategories.filter(category => category._id !== e.value._id);

        setSelectedCategories(_selectedCategories);
    };

    const preDefinedDropdownChange = (e) => {
        setPreDefinedTaskdropdownValue(e.target.value);
        setSelectedCategories([]);
    }

    return (
        <div>
            <Toast ref={toast} />
            {!taskView ?
                <div>
                    <div className="mt-2">
                        <div className='cursor-pointer' onClick={() => setProjectViewScreen(false)} >
                            <img src={back} className="back-logo mr-2" />
                            <span className="PS-title pt-2">All Project / {projectViewDatas?.projectName}</span>
                        </div>
                        <div className="btn2">
                            <div>
                                <Button disabled={subTaskList && subTaskList?.length > 0} className="btn-black mr-2" label="Add" onClick={() => setAddVisible(true)} /></div>
                            {/* <div><Button className="btn-black" label="New" /></div> */}
                        </div>
                    </div>
                    <Divider />
                    <ProjectStatusCard />
                    <div className="table-wrapper mt-4">
                        {subTaskList && subTaskList?.map((item) => {
                            const completedCount = item?.subTasks?.filter((item)=> item.subTaskStatus === "Completed")
                            return (
                                <div>
                                    <div className="card-title px-4 py-3 flex justify-content-between align-items-center">
                                        <h4 className="card-heading m-0 col-2">{item?.headerTitle}</h4>
                                        <h4 className="card-heading m-0 col-2 text-center">On Time</h4>
                                        <h4 className="card-heading m-0 col-2 text-center">
                                            <div className='mx-4'>{(item?.subTaskscompletedPercentage).toFixed(1)}%
                                                <ProgressBar showValue={false} value={item?.subTaskscompletedPercentage} style={{ height: '6px' }} pt={{ value: { style: { background: '#26C10D' } } }}></ProgressBar></div></h4>
                                        <h4 className="card-heading m-0 col-2 text-center">Task  {completedCount && completedCount?.length > 0 ? completedCount?.length : 0} / {item?.subTasksCount}</h4>
                                        <h4 className="card-heading m-0 col-1 text-center">Start Date</h4>
                                        <h4 className="card-heading m-0 col-1 text-center">End Date</h4>
                                        <div className="col-2"></div>
                                    </div>
                                    {item && item?.subTasks?.map((tasks) => {
                                        // const completedSubtaskCount = Math.round((subTaskscompletedPercentage / 100) * subTasksCount);
                                        return (
                                            <div className="flex flex-column xl:flex-row xl:align-items-start px-4 py-2 gap-4 border-bottom-1 border-300">
                                                <div className="flex flex-column sm:flex-row align-items-center flex-1">
                                                    <div className="flex flex-column align-items-center sm:align-items-start gap-3 col-2">
                                                        <div className="text-2xl font-bold text-900">{tasks?.subTaskName}</div>
                                                    </div>
                                                    <div className="text-2xl font-bold text-900 col-2 text-center">{statusBodyTemplate(tasks?.subTaskStatus)}</div>
                                                    <div className="text-2xl font-bold text-900 col-2 text-center flex align-items-center justify-content-center">
                                                        <span class="border-circle p-1 m-2 font-bold flex align-items-center" style={{background : tasks?.completionStatus === "Delayed" ? "red" : tasks?.completionStatus === "OnTime" ? "green" : ""}}></span><span>{tasks?.completionStatus}</span>
                                                    </div>
                                                    <div className="text-2xl font-bold text-900 col-2 text-center">{tasks?.assignTo?.first_name}</div>
                                                    <div className="text-2xl font-bold text-900 col-1 text-center">{formatDate(tasks?.startDate)}</div>
                                                    <div className="text-2xl font-bold text-900 col-1 text-center">{formatDate(tasks?.endDate)}</div>
                                                    <div className="flex align-items-center justify-content-end gap-3 sm:gap-2 w-full">
                                                        <div className='flex justify-content-end gap-2'>
                                                            <Button className="btn-black" label="View" onClick={() => { setTaskView(true); setTochildProjectHeader(item); setTochildProjectHeaderSubTask(tasks) }} />
                                                            <Button className="btn-black" label="Delete" onClick={() => confirm(item?._id, tasks?._id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                        {records && records?.length > 0 ? null : "No Data found"}
                    </div>

                    <Dialog header="Add Pre Defined Task" visible={addVisible} style={{ width: "30vw" }} onHide={HideEdit} breakpoints={{ '960px': '40vw', '641px': '80vw' }}>
                        <div className="flex flex-column gap-3">
                        <div className="flex justify-content-center align-items-center">
                            <Dropdown options={["LV", "EV"]} value={preDefinedTaskdropdownValue} onChange={preDefinedDropdownChange} className='mb-2' />
                        </div>
                            {headerList?.filter((item)=> item.type === preDefinedTaskdropdownValue)?.map((category) => {
                                return (
                                    <div _id={category._id} className="flex align-items-center">
                                        <Checkbox disabled={!preDefinedTaskdropdownValue} inputId={category._id} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item._id === category._id)} />
                                        <label htmlFor={category._id} className="ml-2">
                                            {category.headerTitle}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="flex justify-content-end align-items-center">
                            <Button className="btn-yellow" loading={isLoading} style={{ minWidth: '3rem' }} onClick={onSubmit}>Add</Button>
                        </div>
                    </Dialog>
                </div>
                :
                <div>
                    <div className="cursor-pointer mt-2" onClick={() => {setTaskView(false); getProjectSubTaskByProjectId()}}>
                        <img src={back} className="back-logo mr-2" />
                        <span className="PS-title pt-2">All Project / {projectViewDatas?.projectName} / {tochildProjectHeader?.headerTitle} / {tochildProjectHeaderSubTask?.subTaskName}</span>
                    </div>
                    <hr className="hr-line"></hr>
                    <TaskView headerDetails={tochildProjectHeader} subTaskDetail={tochildProjectHeaderSubTask} />
                </div>
            }
        </div>
    )
}
export default ProjectView
