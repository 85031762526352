import { action, thunk, persist} from "easy-peasy";
import constants from "../constants/constants";
import axios from "axios";


const leaveModel = {
  leaves: [],
  setLeaveList: action((state, payload) => {
    state.leaves = payload;
  }),

  applyleave: thunk(async (actions, payload) => {
    // console.log(payload)
    axios
      .post(constants.URL.APPLY_LEAVE, payload.data , payload.config)
      .then((response) => {
        console.log("applyleave : " + JSON.stringify(response));
      })
      .catch((err) => {
        console.error("applyleave failed");
        console.error(err);
      });
  }),

  // leavelist: thunk(async (actions, payload) => {
  //   console.log(payload)
  //   axios
  //     .get(constants.URL.LEAVE_LIST + payload.data.user_id + "?month=" + payload.data.month + "&year=" + payload.data.year , payload.config)
  //     .then((response) => {
  //       console.log("leavelist : " + JSON.stringify(response.data.results));
  //       actions.setLeaveList(response.data.results)
  //     })
  //     .catch((err) => {
  //       console.error("leave list failed");
  //       console.error(err);
  //     });
  // }),
  
};

export default leaveModel;