import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import constants from "../../constants/constants";
import getHeaders from "../../constants/utils";
import axios from "axios";
import { useStoreState } from "easy-peasy";

const DepartmentConfigSetting = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [departmentList, setDepartmentList] = useState();
    const user = useStoreState((state) => state.loginModel.user);

    useEffect(() => {
        getDepartment();
    }, []);

    const getDepartment = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.METADATA_DEPARTMENT + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                setDepartmentList(resp?.data?.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const onSubmit = (data) => {
        // console.log(data);
        const payload = {
            org_id: user?.org_id?._id,
            department: data?.department,
        }

        setIsLoading(true);
        axios.post(constants.URL.METADATA_DEPARTMENT, payload, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp);
                toast.current.show({ severity: "success", summary: "Success", detail: "Department Added Successfully" });
                reset();
                getDepartment();
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    };

    const defaultValues = { department: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    const handleDelete = (data) => {
        console.log(data);
        setIsLoading(true);
        axios.delete(constants.URL.METADATA_DEPARTMENT + "/" + data?._id, { headers: getHeaders() })
            .then((resp) => {
                getDepartment();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        confirmDialog({
            message: 'Do you want to delete this Department?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleDelete(item),
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
                <div className="blue_bg px-4 py-3 mt-3 relative">
                    <h4 className="blue_bg_heading mb-0">Department Name</h4>
                    <div className="iplus">
                        <i className="pi pi-plus-circle" onClick={() => setVisible(true)} style={{ fontSize: "1.2rem" }}></i>
                        <Dialog header="Add" visible={visible} style={{ width: "20vw" }} onHide={() => setVisible(false)}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                            <div className="formgrid grid">
                                <div className="field col-12">
                                    <label>Department Name</label>
                            <span className="w-full">
                                <Controller
                                    name="department"
                                    control={form.control}
                                    rules={{ required: "Department is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error }, "w-full")} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("department")}</span>
                                </div>
                            </div>

                            <div className="flex justify-content-end">
                                <Button loading={isLoading} className="leave_btn" label="Add" />
                            </div>
                            </form>
                        </Dialog>
                    </div>
                </div>
                {departmentList && departmentList?.length > 0 ?
                <>
                {departmentList?.map((item) => (
                    <div className="flex align-items-center justify-content-between px-4 pt-3">
                        <h6 className="earn_details">{item?.department}</h6>
                        <i className="pi pi-trash" onClick={() => confirm(item)}></i>
                    </div>
                ))}
                </>
                : <div className="p-3">No Data Found</div> }
        </>
    );
};
export default DepartmentConfigSetting;
