import React, { useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useStoreState } from "easy-peasy";
import profile from "../Images/profile.png";
import axios from "axios";
import constants from "../constants/constants";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import getHeaders from "../constants/utils";
import { Divider } from "primereact/divider";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";

const Profile = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const user = useStoreState((state) => state.loginModel.user);

    let defaultValues = { fName: "", lName: "", mob: "", location: "", dob: ""};
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(constants.URL.UPDATE_EMPLOYEE_PROFILE + user?._id, {
                headers: getHeaders(),
            })
            .then((resp) => {
                const data = resp.data.results;
                setValue("fName", data?.first_name);
                setValue("lName", data?.last_name);
                setValue("mob", data?.mobile_no);
                setValue("dob", data?.birth_date ? new Date(data?.birth_date) : null);
                setValue("location", data?.location);
            })
            .catch((e) =>{
                console.error(e)
            } )
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onSubmit = (data) => {
        const payload = {
            first_name: data?.fName,
            last_name: data?.lName,
            mobile_no: data?.mob,
            birth_date: data?.dob,
            location: data?.location,
        };

        setIsLoading(true);
        axios
            .patch(constants.URL.UPDATE_EMPLOYEE_PROFILE + user._id, payload, {
                headers: getHeaders(),
            })
            .then((resp) => {
                // console.log(resp.data.results);
                toast.current.show({ severity:'success', summary: 'Success', detail: "Profile Updated" });
            })
            .catch((e) =>{
                toast.current.show({ severity: "error", summary: "Error", detail: e.response.data.message });
                console.error(e)
            } )
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
        <Toast ref={toast} />
            <div className="card p-fluid rightContent-card" style={{ minHeight: "calc(100vh - 120px)" }}>
                <h4 className="blue_bg_heading">Edit Profile</h4>
                <Divider />
                <div className="card">
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                    <div className="image">{/* <img src={profile} alt="profile" className="dotImage" /> */}</div>
                    <div className="grid p-fluid mt-3">
                        <div className="field col-12 md:col-4">
                            <label htmlFor="name2">First Name<span className="p-error">*</span></label><Controller
                            name="fName"
                            control={form.control}
                            rules={{ required: "First Name is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText  maxLength={50} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                            {/* <InputText keyfilter={/^[a-zA-Z]*$/} maxLength={20} defaultValue={profiles?.first_name || fName} onChange={(e) => setFName(e.target.value)} /> */}
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="email2">Last Name<span className="p-error">*</span></label>
                        <Controller
                            name="lName"
                            control={form.control}
                            rules={{ required: "Last Name is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText maxLength={25}  id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                            {/* <InputText keyfilter={/^[a-zA-Z]*$/} maxLength={20} defaultValue={profiles?.last_name || lName} onChange={(e) => setLName(e.target.value)} /> */}
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="name2">Date Of Birth<span className="p-error">*</span></label>
                        <Controller
                            name="dob"
                            control={form.control}
                            rules={{ required:  "Date of Birth is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Calendar id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.value)} showIcon />
                                    {getFormErrorMessage("dob")}
                                </>
                            )}
                        />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="email2">Mobile Number<span className="p-error">*</span></label>
                        <Controller
                            name="mob"
                            control={form.control}
                            rules={{
                                required: "Mobile Number is required.",
                                validate: (value) => {
                                    if (value && value.length < 10) {
                                        return "Mobile Number must be at least 10 digits.";
                                    }
                                    return true;
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText keyfilter={/^[0-9]+$/} maxLength={10} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage("mob")}
                                </>
                            )}
                        />
                            {/* <InputText maxLength={10} minLength={10} keyfilter={/^([6-9][0-9]*)$/} defaultValue={profiles?.mobile_no || mobile} onChange={(e) => setMobile(e.target.value)} /> */}
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="email2">Location</label>
                        <Controller
                            name="location"
                            control={form.control}
                            // rules={{ required: "Employee No is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                        </div>
                    </div>

                    <div className="inline-flex justify-content-end">
                        <Button icon="pi pi-check" size="small" label="Save" loading={isLoading} />
                    </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Profile;
