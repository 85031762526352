// SessionExpiredModal.jsx
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const SessionExpiredModel = ({ visible, onHide }) => {
    return (
        <Dialog
            header="Session Expired" closable={false} className='text-center'
            visible={visible}
            style={{ width: '30vw' }}
            modal
            onHide={onHide}
            footer={<div className='flex justify-content-center'><Button label="OK" onClick={onHide} /></div>}
        >
            <p>Your session has expired. Please log in again.</p>
        </Dialog>
    );
};

export default SessionExpiredModel;
