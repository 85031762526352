import React, { useEffect, useRef } from "react";
// import "../style/punch.css";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { AutoComplete } from "primereact/autocomplete";
import axios from "axios";
import constants from "../constants/constants";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import getHeaders from "../constants/utils";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { Password } from "primereact/password";
import { Dialog } from "primereact/dialog";

const dropdownItemDesignation = ["Director", "Manager", "Team Lead", "Developer", "Designer", "Tester", "Sales Agent", "Others"];

const dropdownBankName = ["SBI", "HDFC", "City Union Bank", "Kotak Mahendra Bank", "Indian Bank"];
const dropdownRole = ["EMPLOYEE", "PAYSLIP_ADMIN", "EMPLOYEE_ADMIN", "CONFIG_ADMIN"];

const EmployeeAdd = () => {
    const toast = useRef(null);
    const [role, setRole] = useState();
    const [selectedDefault, setSelectedDefault] = React.useState(null);
    const [departmentList, setDepartmentList] = useState();
    const [leaveList, setLeaveList] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // const locations = useLocation();
    // const selectedEmployee = locations?.state?.myData;
    // console.log("employeeDetails",employeeDetails);

    const user = useStoreState((state) => state.loginModel.user);
    const setemployeeList = useStoreActions((actions) => actions.employeeModel.setemployeeList);
    const employeeDetails = useStoreState((actions) => actions.employeeModel.employeeDetails);
    const employeeEditValue = useStoreState((actions) => actions.employeeModel.employeeEditValue);
    const setActiveIndex = useStoreActions((actions) => actions.employeeModel.setActiveIndex);
    const setEmployeeEditValue = useStoreActions((actions) => actions.employeeModel.setEmployeeEditValue);

    let defaultValues = { fName: "", lName: "", emp_no: "", emails: "", mob: "", department: "", manager: "", location: "", designation: "", empType: "", dob: "", doj: "", pan_no: "", pf_uan: "", bank_name: "", acc_no: "", ifsc_code: "", emp_type: "", permissions: "", leave: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    useEffect(() => {
        getDepartment();
        getLeave();
    }, []);

    const getDepartment = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.METADATA_DEPARTMENT + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                setDepartmentList(resp?.data?.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getLeave = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.LEAVE_CONFIG_LIST + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                setLeaveList(resp?.data?.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const [visibility, setVisibility] = useState(false);
    const [titleEarning, setTitleEarning] = useState();
    const [amountEarning, setAmountEarning] = useState("Payable");
    let initialValues = [
        { title: "Basic Pay", type: "Payable", percentage: "", amount: "" },
        { title: "HRA", type: "Payable", percentage: "", amount: "" },
        { title: "DA", type: "Payable", percentage: "", amount: "" },
        { title: "House Rent Allowance", type: "Payable", percentage: "", amount: "" },
        { title: "Travel Allowance", type: "Payable", percentage: "", amount: "" },
        { title: "PF", type: "Payable", percentage: "", amount: "" },
        { title: "ESI", type: "Payable", percentage: "", amount: "" },
        { title: "Income Tax", type: "Payable", percentage: "", amount: "" },
        { title: "Professional Tax", type: "Payable", percentage: "", amount: "" },
        { title: "TDS", type: "Payable", percentage: "", amount: "" },
    ]
    const [earningsAdd, setEarningsAdd] = useState(initialValues);

    const [totalPayable, setTotalPayable] = useState(0);
    const [totalDeduction, setTotalDeduction] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        if (employeeEditValue) {
            setIsLoading(true);
            axios
                .get(constants.URL.UPDATE_EMPLOYEE_PROFILE + employeeEditValue?._id, {
                    headers: getHeaders(),
                })
                .then((resp) => {
                    console.log(resp.data.results);
                    const { first_name, last_name, email, emp_no, joining_date, department, designation, birth_date, location, mobile_no, pan_no, permissions, _idx, bank_info, pf_uan, pay_break_up, emp_type, leave } = resp.data.results;
                    //setSelectedEmployeeData(resp.data.results);
                    setValue("fName", first_name);
                    setValue("lName", last_name);
                    setValue("emails", email);
                    setValue("emp_no", emp_no);
                    setValue("mob", mobile_no);
                    setValue("doj", new Date(joining_date || ''));
                    setValue("dob", new Date(birth_date || ''));
                    setValue("department", department);
                    setValue("designation", designation);
                    setValue("location", location);
                    setValue("pf_uan", pf_uan);
                    setValue("pan_no", pan_no);
                    setValue("emp_type", emp_type);
                    setValue("manager", _idx);
                    setValue("bank_name", bank_info?.name);
                    setValue("acc_no", bank_info?.acc_no);
                    setValue("ifsc_code", bank_info?.ifsc);
                    setValue("permissions", permissions);
                    setValue("leave", leave);
                    setEarningsAdd(pay_break_up && pay_break_up.length > 0 ? pay_break_up : earningsAdd);
                    setSelectedDefault(resp.data.results);
                })
                .catch((e) => console.error(e))
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [employeeEditValue]);

    useEffect(() => {
        let payableTotal = 0;
        let deductionTotal = 0;
        let total = 0;

        earningsAdd.forEach(item => {
            if (item.type === "Payable") {
                payableTotal += parseFloat(item.amount || 0);
            } else if (item.type === "Deduction") {
                deductionTotal += parseFloat(item.amount || 0);
            }
        });

        total = payableTotal - deductionTotal;

        setTotalPayable(payableTotal);
        setTotalDeduction(deductionTotal);
        setTotalAmount(total);
    }, [earningsAdd]);

    const [filteredautokeyprocess, setFilteredautokeyprocess] = useState(null);
    const keyautocompletesss = employeeDetails;

    const searchAutoClassification = (event) => {
        setTimeout(() => {
            let _filteredautokeyprocess;
            if (!event.query.trim().length) {
                _filteredautokeyprocess = [...keyautocompletesss];
            } else {
                _filteredautokeyprocess = keyautocompletesss.filter((keyautocomplete) => {
                    return keyautocomplete.first_name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredautokeyprocess(_filteredautokeyprocess);
        }, 250);
    };
    // console.log(filteredautokeyprocess);

    if (!selectedDefault && employeeEditValue) {
        // wait until default values are set from API response
        return <div className="mt-3 ml-2">Loading...</div>;
    }

    const onSubmit = (data) => {
        const datas = {
            first_name: data?.fName,
            last_name: data?.lName,
            emp_no: data?.emp_no,
            email: data?.emails,
            password: data?.password,
            mobile_no: data?.mob,
            birth_date: data?.dob,
            joining_date: data?.doj,
            designation: data?.designation,
            location: data?.location,
            pan_no: data?.pan_no,
            pf_uan: data?.pf_uan,
            department: data?.department,
            bank_info: {
                name: data?.bank_name,
                ifsc: data?.ifsc_code,
                acc_no: data?.acc_no,
                // _id: selectedEmployeeData?.bank_info?._id,
            },
            permissions: data?.permissions,
            leave: data?.leave,
            org_id: user?.org_id?._id,
            _idx: data?.manager,
            pay_break_up: earningsAdd,
            emp_type: data?.emp_type
        };
        // console.log("payload", datas);
        if (employeeEditValue) {
            setIsLoading(true);
            axios
                .patch(constants.URL.UPDATE_EMPLOYEE_PROFILE + employeeEditValue?._id, datas, {
                    headers: getHeaders(),
                })
                .then((resp) => {
                    // console.log(resp.data.results);
                    toast.current.show({ severity: "success", summary: "Success", detail: "Employee Updated Successfully" });
                    setEmployeeEditValue(null);
                })
                .catch((e) => {
                    console.error(e);
                    toast.current.show({ severity: "error", summary: "Error", detail: e?.response?.data?.message });
                })
                .finally(() => {
                    setIsLoading(false);
                    setActiveIndex(0);
                });
        } else {
            setIsLoading(true);
            axios
                .post(constants.URL.ADD_EMPLOYEE, datas, {
                    headers: getHeaders(),
                })
                .then((resp) => {
                    // console.log(resp.data.results);
                    setemployeeList([...employeeDetails, resp.data.results]);
                    reset();
                    setEarningsAdd(initialValues);
                    setValue("password", '');
                    toast.current.show({ severity: "success", summary: "Success", detail: "Employee Added Successfully" });
                })
                .catch((e) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: e.response.data.message });
                    console.error(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const itemTemplate = (item) => {
        return (
            <div className="flex flex-column">
                <div>{item.first_name}</div>
                <div>{item.email}</div>
            </div>
        );
    };

    const handleEarnings = () => {
        const data = {
            title: titleEarning,
            type: amountEarning,
            amount: "",
            percentage: ""
        };
        setEarningsAdd([...earningsAdd, data]);
        setTitleEarning("");
        setAmountEarning("");
    };

    const handleInputChange = (index, field, value) => {
        const updatedEarnings = [...earningsAdd];
        updatedEarnings[index][field] = value;
    
        if (index === 0 && field === "amount") {
            updatedEarnings[index].percentage = 100;
            // Update the amounts for all other entries based on their percentages
            updatedEarnings.forEach((entry, i) => {
                if (i !== 0 && entry.percentage) {
                    entry.amount = value * (entry.percentage / 100);
                }
            });
        } else if (index !== 0 && field === "percentage") {
            updatedEarnings[index].amount = updatedEarnings[0].amount * (value / 100);
        } else if (index !== 0 && field === "amount") {
            updatedEarnings[index].percentage = (value / updatedEarnings[0].amount) * 100;
        }
    
        setEarningsAdd(updatedEarnings);
    };
    console.log(earningsAdd);
    
    const leaveTypeTemplate = (option) => {
        return (
            <div className="flex flex-column">
                <div>{option.category}</div>
                <div>{option.type}</div>
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="blue_bg px-4 py-3 mt-3">
                <h4 className="blue_bg_heading mb-0">Basic Details</h4>
            </div>
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                <div className="p-fluid formgrid grid mt-3 px-2">
                    <div className="field col-12 md:col-4">
                        <label htmlFor="name2">
                            First Name<span className="p-error">*</span>
                        </label>
                        {/* <InputText value={fName} onChange={(e) => setFName(e.target.value)} /> */}
                        <Controller
                            name="fName"
                            control={form.control}
                            rules={{ required: "First Name is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="email2">
                            Last Name<span className="p-error">*</span>
                        </label>
                        <Controller
                            name="lName"
                            control={form.control}
                            rules={{ required: "Last Name is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="emp_no">Employee No<span className="p-error">*</span></label>
                        <Controller
                            name="emp_no"
                            control={form.control}
                            rules={{ required: "Employee No is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="email2">
                            Email ID<span className="p-error">*</span>
                        </label>
                        <Controller
                            name="emails"
                            defaultValue={selectedDefault?.email}
                            control={form.control}
                            rules={{
                                required: "Email ID is required.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="name2">
                            Password<span className="p-error">*</span>
                        </label>
                        {/* <InputText value={password} onChange={(e) => setPassword(e.target.value)} /> */}
                        {selectedDefault ? (
                            <Controller
                                name="password"
                                control={form.control}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Password id={field.name} value={field.value} toggleMask feedback={false} disabled={selectedDefault} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        ) : (
                            <Controller
                                name="password"
                                control={form.control}
                                rules={{ required: "Password is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Password id={field.name} value={field.value} toggleMask feedback={false} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        )}
                    </div>
                    <div className="field col-12 md:col-4">
                        <label className="col-6" htmlFor="mob">
                            Mobile Number<span className="p-error">*</span>
                        </label>
                        <Controller
                            name="mob"
                            control={form.control}
                            rules={{
                                required: "Mobile Number is required.",
                                validate: (value) => {
                                    if (value && value.length < 10) {
                                        return "Mobile Number must be at least 10 digits.";
                                    }
                                    return true;
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText keyfilter={/^[0-9]+$/} maxLength={10} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage("mob")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="name2">Date Of Birth</label>
                        <Controller
                            name="dob"
                            control={form.control}
                            // rules={{ required:  "Date of Birth is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Calendar id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.value)} showIcon />
                                    {getFormErrorMessage("dob")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="email2">Joining Date</label>
                        <Controller
                            name="doj"
                            control={form.control}
                            // rules={{ required:  "Joining Date is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Calendar id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} showIcon />
                                    {getFormErrorMessage("doj")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label className="emp-field" htmlFor="designation">
                            Designation
                        </label>
                        <Controller
                            name="designation"
                            control={form.control}
                            // rules={{ required: "Designation is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Dropdown options={dropdownItemDesignation} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage("designation")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="location">Location</label>
                        <Controller
                            name="location"
                            control={form.control}
                            // rules={{ required: "Employee No is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="department">Department<span className="p-error">*</span></label>
                        <Controller
                            name="department"
                            control={form.control}
                            rules={{ required: "Department is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Dropdown options={departmentList} optionLabel="department" optionValue="department" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage("department")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="state">Reporting Person<span className="p-error">*</span></label>
                        {/* <AutoComplete
                            style={{ borderLeft: "none", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}
                            value={reportingperson}
                            // value={reportingperson ? reportingperson : options.value}
                            onChange={(e) => {
                                // options.editorCallback(e.value);
                                setReportingPerson(e.target.value);
                                //console.log(e.target.value);
                            }}
                            suggestions={filteredautokeyprocess}
                            field="first_name"
                            completeMethod={searchAutoClassification}
                            aria-label="Reporting Person"
                            dropdownAriaLabel="Reporting Person"
                            itemTemplate={itemTemplate}
                        /> */}
                        <Controller
                            name="manager"
                            control={form.control}
                            rules={{ required: "Reporting Person is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Dropdown options={employeeDetails} optionLabel="first_name" optionValue="_id" itemTemplate={itemTemplate} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage("manager")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="permissions">Permissions<span className="p-error">*</span></label>
                        <span className="min-w-full">
                        <Controller name="permissions" control={form.control}
                            rules={{ required: "Permissions is required." }}
                            render={({ field, fieldState }) => (
                                <>
                        <MultiSelect options={dropdownRole} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                        {getFormErrorMessage("permissions")}
                                </>
                            )}
                        /></span>
                        </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="name2">Employment Type</label>
                        <Controller
                            name="emp_type"
                            control={form.control}
                            render={({ field, fieldState }) => (
                                <>
                                    <Dropdown options={["Employee", "Consultant", "Contract"]} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage("emp_type")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label htmlFor="leave">Leave<span className="p-error">*</span></label>
                        <span className="min-w-full">
                        <Controller name="leave" control={form.control}
                            rules={{ required: "Leave is required." }}
                            render={({ field, fieldState }) => (
                                <>
                        <MultiSelect options={leaveList} optionLabel="type" optionValue="_id" itemTemplate={leaveTypeTemplate} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error }, "w-full")} onChange={(e) => field.onChange(e.target.value)}></MultiSelect>
                        {getFormErrorMessage("leave")}
                                </>
                            )}
                        />
                        </span>
                        </div>
                </div>
                <div className="blue_bg px-4 py-3 mt-3">
                    <h4 className="blue_bg_heading mb-0">Bank Details</h4>
                </div>
                <div className="p-fluid formgrid grid mt-3 px-2">
                    <div className="field col-12 md:col-4 pb-4">
                        <label htmlFor="state">Bank Name<span className="p-error">*</span></label>
                        <Controller
                            name="bank_name"
                            control={form.control}
                            rules={{ required: "Bank Name is required." }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Dropdown options={dropdownBankName} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    {getFormErrorMessage("bank_name")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4 pb-4">
                        <label htmlFor="acc_no">Account No<span className="p-error">*</span></label>
                        <Controller
                            name="acc_no"
                            control={form.control}
                            rules={{
                                required: "Account No is required."
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText keyfilter={/^[0-9]+$/} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage("acc_no")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4 pb-4">
                        <label htmlFor="name2">IFSC Code<span className="p-error">*</span></label>
                        <Controller
                            name="ifsc_code"
                            control={form.control}
                            rules={{
                                required: "IFSC Code is required."
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText maxLength={11} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage("ifsc_code")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4 pb-4">
                        <label htmlFor="pan_no">PAN No<span className="p-error">*</span></label>
                        <Controller
                            name="pan_no"
                            control={form.control}
                            rules={{
                                required: "PAN No is required.",
                                validate: (value) => {
                                    if (value && value.length < 10) {
                                        return "PAN No must be at least 10 digits.";
                                    }
                                    return true;
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText maxLength={10} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage("pan_no")}
                                </>
                            )}
                        />
                    </div>
                    <div className="field col-12 md:col-4 pb-4">
                        <label htmlFor="pf_uan">PF No</label>
                        <Controller
                            name="pf_uan"
                            control={form.control}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    {getFormErrorMessage("pf_uan")}
                                </>
                            )}
                        />
                    </div>
                </div>



                <div className="blue_bg px-4 py-3 mt-3 relative">
                    <h4 className="blue_bg_heading mb-0">Pay Break-up</h4>
                    <div className="iplus">
                        <i className="pi pi-plus-circle" onClick={() => setVisibility(true)} style={{ fontSize: "1.2rem" }}></i>
                        <Dialog header="Add Pay Break-Up" visible={visibility} style={{ width: "40vw" }} onHide={() => setVisibility(false)}>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="name2">Header</label>
                                    <InputText value={titleEarning} placeholder="" onChange={(e) => setTitleEarning(e.target.value)} className="w-full" />
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="name2" className="mb-4"></label>
                                    <Dropdown options={["Payable", "Deduction"]} value={amountEarning} placeholder="Amount" onChange={(e) => setAmountEarning(e.target.value)} className="w-full" />
                                </div>
                            </div>
                            <div className="flex justify-content-end">
                                <Button onClick={handleEarnings} className="leave_btn" label="Add" />
                            </div>
                        </Dialog>
                    </div>
                </div>

                <div className="flex align-items-center justify-content-between mt-3 flex align-items-center">
                    <h6 className="earn_details pl-3 pt-3">Detail</h6>
                    <div className="">
                        <span></span>
                        <span style={{ width: "75px" }} className="mx-2">Percentage</span>
                        <span style={{ width: "75px" }}>Amount</span>
                    </div>
                </div>

                <div className="earn_line mb-3"></div>

                {earningsAdd?.map((item, index) => (
                    <div className="flex align-items-center justify-content-between">
                        <h6 className="earn_details pl-3 pt-3">{item.title}</h6>
                        <div className="">
                            <Dropdown options={["Payable", "Deduction"]} value={item?.type} disabled={item?.title === "Basic Pay"} onChange={(e) => handleInputChange(index, 'type', e.target.value)} style={{ width: "125px" }} />
                            <InputText value={item?.percentage} onChange={(e) => handleInputChange(index, 'percentage', e.target.value)} placeholder="" style={{ width: "75px" }} className="mx-2" />
                            <InputText value={item?.amount} onChange={(e) => handleInputChange(index, 'amount', e.target.value)} placeholder="" style={{ width: "75px" }} />
                        </div>
                    </div>
                ))}

                <div className="blue_bg px-4 py-3 mt-3 relative">
                    <h4 className="blue_bg_heading mb-0">Total</h4>
                    <div className="iplus">{totalAmount}</div>
                </div>
                <div className="flex align-items-center justify-content-between mr-3 mt-3">
                    <h6 className="earn_details pl-3 pt-3">Monthly Payable</h6>
                    <div className="">
                        {totalPayable}
                    </div>
                </div>
                <div className="flex align-items-center justify-content-between mr-3">
                    <h6 className="earn_details pl-3 pt-3">Deduction</h6>
                    <div className="">
                        {totalDeduction}
                    </div>
                </div>
                <div className="flex align-items-center justify-content-between mr-3">
                    <h6 className="earn_details pl-3 pt-3">Monthly CTC</h6>
                    <div className="">
                        {totalAmount}
                    </div>
                </div>

                <div className="flex justify-content-center mt-3">
                    <Button icon="pi pi-check" size="small" label="Save" loading={isLoading} />
                </div>
            </form>
        </>
    );
};

export default EmployeeAdd;
