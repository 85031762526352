import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import constants from "../constants/constants";
import axios from "axios";
import { useStoreState } from "easy-peasy";
import getHeaders from "../constants/utils";
import { Toast } from "primereact/toast";

const ConpensationApproval = () => {
    const [isLoading, setIsLoading] = useState(false);
    const user = useStoreState((state) => state.loginModel.user);
    const employeeDetails = useStoreState((actions) => actions.employeeModel.employeeDetails);
    const [details, setDetails] = useState();
    const toast = useRef(null);
    
    useEffect(() => {
        getCompensationData();
    }, []);

    const getCompensationData = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.GET_COMPENSATION_BY_MANAGER_ID + user?._id, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setDetails(resp.data.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const priceBodyTemplate = (e) => {
        return (
            <div className="flex flex-wrap gap-3">
                <Button disabled={isLoading} label="Approve" onClick={()=> handleApprove(e)} className="p-button-success mr-2" />
                <Button disabled={isLoading} label="Deny" onClick={()=> handleDeny(e)} className="p-button-danger mr-2" />
            </div>
        );
    };

    const handleApprove = (e) => {
        const data = {
            status: "COMPENSATION APPROVED"
        }
        setIsLoading(true);
        axios
        .patch(constants.URL.UPDATE_COMPENSATION_STATUS + e._id, data, {
            headers: getHeaders(),
        })
        .then((resp) => {
            getCompensationData();
            toast.current.show({ severity:'success', summary: 'Success', detail: "Approved Successfully" });
        })
        .catch((e) =>{
            toast.current.show({ severity: "error", summary: "Failure", detail: "Failed to Approve" });
            console.error(e)
        } )
        .finally(() => {
            setIsLoading(false);
        });
    }
    
    const handleDeny = (e) => {
        const data = {
            status: "DENIED" 
        }

        setIsLoading(true);
        axios
        .patch(constants.URL.UPDATE_COMPENSATION_STATUS + e._id, data, {
            headers: getHeaders(),
        })
        .then((resp) => {
            getCompensationData();
            toast.current.show({ severity:'success', summary: 'Success', detail: "Denied Successfully" });
        })
        .catch((e) =>{
            toast.current.show({ severity: "error", summary: "Failure", detail: "Failed to Deny" });
            console.error(e)
        } )
        .finally(() => {
            setIsLoading(false);
        });
    }

    const getUser = (user_id)=>{
        return employeeDetails?.find((item) => item?._id === user_id);
    }

    return (
        <div>
        <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card mt-3">
                        <DataTable value={details} loading={isLoading} responsiveLayout="scroll">
                            <Column field="user_id" header="Employee" body={(details) =>{
                              let u = getUser(details.user_id)
                              return u?.first_name +" "+ u?.last_name
                            }
                            }/>
                            <Column
                                field="from_date"
                                header="From"
                                body={(details) => {
                                    const date = new Date(details?.from_date);
                                    return date?.toLocaleDateString();
                                }}
                            ></Column>
                            <Column
                                field="to_date"
                                header="To"
                                body={(details) => {
                                    const date = new Date(details?.to_date);
                                    return date?.toLocaleDateString();
                                }}
                            ></Column>
                            <Column field="no_of_days" header="No of Days"></Column>
                            <Column field="reason" header="Reason" ></Column>
                            <Column field="Approval" header="Approval" body={(e)=> priceBodyTemplate(e)}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConpensationApproval;