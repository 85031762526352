import loginModel from './loginModel'
import leaveModel from './leaveModel'
import payslipModel from './payslipModel'
import employeeModel from './employeeModel'
import projectsModel from './projectsModel'
import tabModel from './tabModel'

const models = {
    loginModel: loginModel,
    leaveModel: leaveModel,
    payslipModel: payslipModel,
    employeeModel: employeeModel,
    projectsModel: projectsModel,
    tabModel: tabModel,
}

export default models