import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useStoreState } from 'easy-peasy';
import getHeaders from '../constants/utils';
import constants from '../constants/constants';
import axios from 'axios';

const LeaveDashboard = () => {
    const [records, setRecords] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const user = useStoreState((state) => state.loginModel.user);

    useEffect(() => {
        setIsLoading(true);
        getAllowedLeaveTypes();
    }, []);

    const getAllowedLeaveTypes = () => {
        axios.get(constants.URL.ALLOWED_LEAVE_TYPES + user?._id + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.results);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const customTemplate = (rowData) => {
        return <div>{rowData?.type === "Compensation" ? rowData?.validity + " Months" : rowData?.validity}</div>
    }

    return (
        <div>
            <div className="mt-3">
                <div className="card">
                    <DataTable value={records} loading={isLoading} responsiveLayout="scroll">
                        <Column field="category" header="Category"></Column>
                        <Column field="type" header="Type"></Column>
                        <Column field="limit" header="Limit"></Column>
                        <Column field="allowed" header="Allowed"></Column>
                        {/* <Column field="appliedleave" header="Applied Leave" ></Column> */}
                        <Column field="allowedLeaves" header="Available" ></Column>
                        <Column field="approvedleave" header="Taken" ></Column>
                        {/* <Column field="pendingForLeaveApproval" header="Pending Requests"></Column> */}
                        <Column field="restrictions" header="Restrictions"></Column>
                        <Column field="maxmin" header="Max/Min"></Column>
                        <Column field="validity" header="Validity" body={customTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}

export default LeaveDashboard