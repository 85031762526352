/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { useStoreState } from "easy-peasy";
import axios from "axios";
import constants from "../constants/constants";
import { Toast } from "primereact/toast";
import getHeaders from "../constants/utils";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const PayslipSummary = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const user = useStoreState((state) => state.loginModel.user);
    const [months, setMonths] = useState(null);
    const [years, setYears] = useState(null);

    const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const year = ["2022", "2023", "2024", "2025", "2026"];

    useEffect(() => {
        setIsLoading(true);
        const date = new Date();
        const currentMonth = date.getMonth() - 1;
        const currentYear = date.getFullYear();
        const monthFind = month.find((item, index) => {return index == currentMonth});
        const findYear = year.find((item) => {return item == currentYear});
        
        getPayslipSummaryList(monthFind, findYear);
        setMonths(monthFind);
        setYears(findYear);
    }, []);

    const getPayslipSummaryList = (monthFind, findYear) => {
        axios.get(constants.URL.GET_PAYSLIP_SUMMARY + "/" + monthFind+"-"+findYear + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.results);
            }).catch((e) => {
                setRecords();
                console.error(e);
                toast.current.show({ severity: "error", summary: "Error", detail: e?.response?.data?.message });
            }).finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(async () => {
        if (months && years) {
            getPayslipSummaryList(months, years);
        }
    }, [months, years]);

    return (
        <div>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12 mt-3">
                    <div className="card p-fluid rightContent-card pt-3">
                        <div className="md:flex flex-wrap align-items-center justify-content-between mb-3">
                            <h6 className="blue_bg_heading md:mb-0">Payslip Summary</h6>
                            <div className="md:inline-flex flex flex-wrap align-items-center justify-content-center">
                                <Dropdown value={months} onChange={(e) => setMonths(e.target.value)} options={month} placeholder="Month" className="w-8rem m-1" />
                                <Dropdown value={years} onChange={(e) => setYears(e.target.value)} options={year} placeholder="Year" className="w-8rem m-1" />
                            </div>
                        </div>
                        <DataTable className='' value={records} responsiveLayout="scroll">
                            <Column field="name" header="Name"></Column>
                            <Column field="ctc" header="CTC"></Column>
                            <Column field="basic_pay" header="Basic Pay"></Column>
                            {/* <Column field="totalEarnings" header="Earnings"></Column> */}
                            <Column field="totalDeductions" header="Pay Deduction"></Column>
                            <Column field="lopDays" header="LOP Days"></Column>
                            <Column field="cl" header="CL Days"></Column>
                            <Column field="lop_amount" header="LOP Deduction"></Column>
                            <Column field="netPay" header="Monthly Payable"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayslipSummary;