import React, { useEffect, useState, useRef } from "react";
import "../style/Pay.css";
import { useStoreActions, useStoreState } from "easy-peasy";
import { AutoComplete } from "primereact/autocomplete";
import axios from "axios";
import constants from "../constants/constants";
import { Toast } from "primereact/toast";
import getHeaders from "../constants/utils";
import { Divider } from "primereact/divider";
import html2pdf from 'html2pdf.js';
import { Dropdown } from "primereact/dropdown";

const AllPaySlip = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [monthList, setMonthList] = useState(null);
    const setpayslipList = useStoreActions((actions) => actions.payslipModel.setpayslipList);
    const payslipDetails = useStoreState((actions) => actions.payslipModel.payslipDetails);
    const [employeeName, setEmployeeName] = useState(null);
    const employeeDetails = useStoreState((actions) => actions.employeeModel.employeeDetails);
    const toast = useRef(null);
    const user = useStoreState((state) => state.loginModel.user);
    const [approveStatus, setApproveStatus] = useState(false);
    const [date, setDate] = useState(null);

    useEffect(async () => {
        setpayslipList(null);
        getPayslipDropdownList();
    }, []);

    useEffect(async () => {
        if (date && employeeName) {
            getPayslip(date);
        }
    }, [date, employeeName]);

    const getPayslipDropdownList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.PAYSLIP_DROPDOWN_LIST + user?._id, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setMonthList(resp.data.results);
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const [filteredautokeyprocess, setFilteredautokeyprocess] = useState(null);
    const keyautocompletesss = employeeDetails;

    const searchAutoClassification = (event) => {
        setTimeout(() => {
            let _filteredautokeyprocess;
            if (!event.query.trim().length) {
                _filteredautokeyprocess = [...keyautocompletesss];
            } else {
                _filteredautokeyprocess = keyautocompletesss.filter((keyautocomplete) => {
                    return keyautocomplete.first_name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredautokeyprocess(_filteredautokeyprocess);
        }, 250);
    };

    useEffect(async () => {
        setpayslipList("");
        setApproveStatus(false);
    }, [approveStatus]);

    const itemTemplate = (item) => {
        return (
            <div className="flex flex-column">
                <div>{item.first_name}</div>
                <div>{item.email}</div>
            </div>
        );
    };

    const getPayslip = (dates) => {
        setIsLoading(true);
        axios
        .get(constants.URL.PAYSLIP_RELEASE + "/" + dates + "/" + employeeName, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setpayslipList(resp.data.results);
            })
            .catch((e) => {
                setpayslipList(null);
                toast.current.show({ severity: "error", summary: "Failure", detail: e.response.data.message });
                console.error(e)
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    function convertToWords(num) {
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
        const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

        if (num === 0) {
            return "Zero";
        }

        if (num < 0) {
            return `Negative ${convertToWords(Math.abs(num))}`;
        }

        function convertInteger(num) {
            let words = "";

            if (num >= 1000) {
                words += `${convertInteger(Math.floor(num / 1000))} Thousand `;
                num %= 1000;
            }

            if (num >= 100) {
                words += `${ones[Math.floor(num / 100)]} Hundred `;
                num %= 100;
            }

            if (num >= 20) {
                words += `${tens[Math.floor(num / 10)]} `;
                num %= 10;
            } else if (num >= 10) {
                words += `${teens[num - 10]} `;
                num = 0;
            }

            if (num > 0) {
                words += `${ones[num]} `;
            }

            return words.trim();
        }

        // Split into integer and fractional parts
        const parts = num.toString().split(".");
        const integerPart = parseInt(parts[0], 10);
        const fractionalPart = parts[1] ? parseInt(parts[1].padEnd(2, '0'), 10) : 0;

        let words = `${convertInteger(integerPart)} Rupees`;

        if (fractionalPart > 0) {
            words += ` and ${convertInteger(fractionalPart)} Paise`;
        }

        return words.trim();
    }

    const generatePDF = () => {
        const element = document.getElementById('payslip-content');
        const opt = {
            //   margin: 1,
            margin: [0.3, 0.3, 0.3, 0.3],
            filename: 'Payslip_'+date+'.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    };

    const formatDate = (value) => {
        const date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="card p-fluid mt-3">
                <div className="sm:flex flex-wrap align-items-center justify-content-between mb-3">
                    <h6 className="blue_bg_heading md:mb-0">Payslip</h6>
                            <div className="sm:inline-flex flex align-items-center justify-content-center">
                        {/* <div className="mr-3 p-input-icon-right"> */}
                            {/* <AutoComplete
                                placeholder="search employee name"
                                style={{ borderLeft: "none", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}
                                value={employeeName}
                                onChange={(e) => {
                                    setEmployeeName(e.target.value);
                                    console.log(e.target.value);
                                }}
                                suggestions={filteredautokeyprocess}
                                field="first_name"
                                itemTemplate={itemTemplate}
                                completeMethod={searchAutoClassification}
                                aria-label="search employee name"
                                dropdownAriaLabel="search employee name"
                            /> */}
                            {/* <i className="pi pi-search"></i> */}
                            {/* <InputText style={{ borderLeft: "none", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} placeholder="search employee name" /> */}
                        {/* </div> */}
                            <Dropdown options={employeeDetails} optionLabel="first_name" optionValue="_id" value={employeeName} itemTemplate={itemTemplate}
                                onChange={(e) => { setEmployeeName(e.target.value)}} placeholder="Select Employee Name" className="w-10rem md:w-15rem mr-2" />
                        <Dropdown options={monthList} value={date} onChange={(e) => setDate(e.value)} view="month" dateFormat="M-yy" className="w-7rem md:w-10rem mr-2" placeholder="Month filter" />
                        <i className="pi pi-download" onClick={generatePDF} />
                    </div>
                </div>
                <Divider />

                {payslipDetails ? (
                    <div id="payslip-content">
                        <h3 className="payslip_org_name">{user.org_id.name}</h3>
                        <p className="payslip_org_ad">{user.org_id.address}</p>
                        <div className="payslip_heading py-3">Pay Statement for the month of {date}
                        </div>
                        <div className="pay_sum_text mt-3">
                            Employee Pay Summary
                        </div>
                        <Divider />
                            <div className="flex flex-column sm:flex-row align-items-center">
                                <div className="col-12 sm:col-8 p-0">
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Employee Name</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.first_name}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Employee ID</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.emp_no}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Date of joining</div>
                                        <span className="payslip_value">{formatDate(payslipDetails?.user_id?.joining_date)}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Designation</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.designation}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Department</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.department}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Pay Period</div>
                                        <span className="payslip_value">{date}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Pay Date</div>
                                        <span className="payslip_value">{formatDate(payslipDetails?.payDate)}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Bank Name</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.bank_info?.name}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">Bank Number</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.bank_info?.acc_no}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">PAN NO</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.pan_no}</span>
                                    </div>
                                    <div className="flex align-items-center mb-1">
                                        <div className="payslip_label col-6 sm:col-3">PF UAN</div>
                                        <span className="payslip_value">{payslipDetails?.user_id?.pf_uan}</span>
                                    </div>
                                </div>
                                <div className="col-12 sm:col-4 p-0">
                                    <div className="card sm:w-max w-full flex flex-column align-items-center custom-card sm:justify-content-left justify-content-center">
                                        <h5 className="pay_sum_text">Employee Net Pay</h5>
                                        <p className="pay_amount">&#x20B9; {payslipDetails?.netPay}</p>
                                        <p>Paid Days: {payslipDetails?.daysInMonth} | LOP Days: {payslipDetails?.lopDays}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="CardContent flex flex-column md:flex-row justify-content-between">
                                <div className="col-12 md:col-6">
                                    <div className="">
                                        <h5 className="card-head text-center mb-0 py-3">Earnings</h5>

                                        <div className="payslip_earnings_card p-0 m-0">
                                            {payslipDetails &&
                                                payslipDetails?.earnings?.map((item) => {
                                                    return (
                                                        <div className="list-content flex flex-row align-items-center justify-content-between p-2">
                                                            <div className="payslip_label">{item.title}</div>
                                                            <span className="payslip_value">{item.amount}</span>
                                                        </div>
                                                    );
                                                })}
                                            <Divider className="my-0" />
                                            <div className="flex flex-row align-items-center justify-content-between p-2">
                                                <div className="payslip_label">Gross Earnings</div>
                                                <span className=" payslip_value">&#x20B9; {payslipDetails?.totalEarnings}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="">
                                        <h5 className="card-head text-center py-3 mb-0">Deductions</h5>

                                        <div className="payslip_earnings_card p-0 m-0">
                                            {payslipDetails &&
                                                payslipDetails?.deductions?.map((item) => {
                                                    return (
                                                        <div className="list-content flex flex-row align-items-center justify-content-between p-2">
                                                            <div className="payslip_label">{item.title}</div>
                                                            <span className=" payslip_value">{item.amount}</span>
                                                        </div>
                                                    );
                                                })}
                                            <Divider className="my-0" />
                                            <div className="flex flex-row align-items-center justify-content-between p-2">
                                                <div className="payslip_label">Total Deductions</div>
                                                <span className=" payslip_value">&#x20B9; {payslipDetails?.totalDeductions}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center my-3">
                                <span className="pay-footer">
                                    <span className="pay-footer-bold">Net Pay for the month</span> (Gross Earnings – Total Deductions): <span className="pay-footer-bold">&#x20B9; {payslipDetails?.netPay}/- ({convertToWords(payslipDetails?.netPay)})</span>
                                </span>
                            </div>
                    </div>
                ) : (
                    <div>No results found</div>
                )}
            </div>
        </>
    );
};

export default AllPaySlip;
