import { useForm } from "react-hook-form";
import "./login.css";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import clanizon from "./Images/clanizon.png";
import axios from "axios";
import constants from "./constants/constants";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Toast } from "primereact/toast";

const Resetpwd = () => {
    const toast = useRef(null);
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { register, handleSubmit, reset, formState: { errors }, watch, getValues } = useForm();
    const emailVerificationResponse = useStoreState((state) => state.loginModel.emailVerificationResponse);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    useEffect(() => {
        if(!emailVerificationResponse){
            history.replace("/")
        }
    }, []);

    const onSubmit = (data) => {
        const payload = {
            password: data.password,
        };
        setIsLoading(true);
        axios.patch(constants.URL.RESET_PASSWORD + emailVerificationResponse, payload)
            .then((resp) => {
                setIsSuccess(true)
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    };

    return (
        <div>
        <Toast ref={toast} />
            <div className="login_container">
                <div className="md:col-5 hidden md:flex p-0" style={{ height: '100%' }}>
                    <img src={clanizon} alt="clanizon" className="clanizonlogo" />
                </div>

                <div className="col-12 md:col-7 flex align-items-center justify-content-center">
                    <div className=" p-4 w-full lg:w-6">
                        {isSuccess ?
                        <div className="p-3 text-center" style={{color: '#493BD7', background: '#E2DFFF'}}>Password Reset Successfully</div>
                        :
                        <>
                            <div className="text-left mb-5">
                                <div className="text-900 text-3xl font-medium mb-3">Reset Password?</div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
                                <div>
                                    <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                                    <div className="relative">
                                        <InputText id="password" type={showPassword ? "text" : "password"}
                                            className={`w-full mb-3 ${errors.password ? 'p-invalid' : ''}`}
                                            {...register("password", {
                                                required: "This field is required",
                                                minLength: {value: 8, message: "Password cannot be less than 8 characters"},
                                                maxLength: {value: 20, message: "Password cannot exceed 10 characters"},
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
                                                    message: "Must contain 1 uppercase letter, 1 number, and 1 special character"
                                                }
                                            })}
                                        />
                                        <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                                            {showPassword ? (
                                                <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                            ) : (
                                                <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                            )}
                                        </span>
                                    </div>
                                    {errors.password && <p className="p-error">{errors.password.message}</p>}

                                    <label htmlFor="confirmPassword" className="block text-900 font-medium mb-2">Confirm Password</label>
                                    <div className="relative">
                                        <InputText id="confirmPassword" type={showConfirmPassword ? "text" : "password"}
                                            className={`w-full mb-3 ${errors.confirmPassword ? 'p-invalid' : ''}`}
                                            {...register("confirmPassword", {
                                                required: "This field is required",
                                                validate: value => value === watch("password") || "Passwords do not match"
                                            })}
                                        />
                                        <span className="absolute eye-icon-position cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
                                            {showConfirmPassword ? (
                                                <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                            ) : (
                                                <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                            )}
                                        </span>
                                    </div>
                                    {errors.confirmPassword && <p className="p-error">{errors.confirmPassword.message}</p>}
                                </div>

                                <Button label="Reset Password" className="w-full mt-3" />
                            </form>
                        </>
}
                        <div className="text-blue-500 text-center mt-2 font-medium line-height-3 cursor-pointer" onClick={()=> history.replace("/")}>Back to login</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resetpwd;
