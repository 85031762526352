import 'react-app-polyfill/ie11';
import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
//import * as serviceWorker from './serviceWorker';
// import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import Main from './main';
import models from "./models/models";
import { StoreProvider, createStore, } from "easy-peasy";

const storeModel = createStore(models);

function Root() {
    return (
            <StoreProvider store={storeModel}>
                <Main />
            </StoreProvider>
            )
        }

export default Root;