import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
//import * as serviceWorker from './serviceWorker';
// import { HashRouter } from 'react-router-dom'
// import ScrollToTop from './ScrollToTop';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';

ReactDOM.render(
    <React.StrictMode>

<GoogleOAuthProvider clientId="458949637582-69lji30t59a5sb5v4qt85c6mn9g7rvgf.apps.googleusercontent.com">
        <Root />
        </GoogleOAuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();