import { useStoreActions } from "easy-peasy";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Toast } from 'primereact/toast';
import "../login.css";
import React, { useState, useRef, useEffect } from "react";
import clanizon from "../Images/clanizon.png";
import axios from "axios";
import constants from "../constants/constants";
import { Image } from "primereact/image";
import { useGoogleLogin } from '@react-oauth/google';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const setUser = useStoreActions((actions) => actions.loginModel.setUser);
    const setEmailVerificationResponse = useStoreActions((actions) => actions.loginModel.setEmailVerificationResponse);
    const history = useHistory();
    const toast = useRef(null);

    useEffect(async () => {
        setUser([]);
        setEmailVerificationResponse();
    }, []);

    const handleGoogleLogin = (tokenResponse) => {
        console.log(tokenResponse); // Check the actual response here
        const payload = {
            googleToken: tokenResponse.access_token,
        };
        handleLogin(payload);
    };

    const login = useGoogleLogin({
        scope: "openid profile email",
        onSuccess: handleGoogleLogin,
        onError: (error) => {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Google Sign-In failed" });
        }
        //    ux_mode= 'redirect'
        //    redirect_uri='http://localhost:6001/api/auth/google/callback'
    });

    const handleLogin = (payload) => {
        setIsLoading(true);
        axios.post(constants.URL.LOGIN, payload)
            .then((resp) => {
                setUser(resp.data.results);
                goto("/app/defaultnav")
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    };

    const goto = (to) => {
        history.replace(to);
    }

    return (
        <div>
            <Toast ref={toast} />
            <div className="login_container">
                <div className="md:col-5 hidden md:flex p-0" style={{ height: '100%' }}>
                    <div></div>
                    <img src={clanizon} alt="clanizon" className="clanizonlogo" />
                </div>

                <div className="col-12 md:col-7 flex align-items-center justify-content-center">
                    <div className="p-4 w-full lg:w-6">
                        <div className="text-center mb-5 pb-5">
                            <div className="agaram-heading">AGARAM</div>
                        </div>
                        <div className="google-signin">
                            <Button
                                label="Sign In"
                                className="w-full mb-2"
                                onClick={login}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
