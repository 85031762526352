import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constants from '../../constants/constants';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useStoreState } from 'easy-peasy';
import back from "../../Images/projects/black.svg";
import getHeaders from '../../constants/utils';
import { Controller, useForm } from "react-hook-form";
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import ProjectTaskHeader from './ProjectTaskHeader';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ProjectsDefinition = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [subTaskList, setSubTaskList] = useState();
    const [userList, setUserList] = useState();
    const [records, setRecords] = useState();
    const [tochild, setTochild] = useState(null);
    const [tochildSubTask, setTochildSubTask] = useState(null);
    const [subTaskEdit, setSubTaskEdit] = useState(null);
    const [viewScreen, setViewScreen] = useState(false);
    const [taskScreen, setTaskScreen] = useState(false);
    const [taskView, setTaskView] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        getProjectTaskHeader();
        getUserList();
    }, []);

    const getProjectTaskHeader = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.TASK_HEADER, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getSubTask = (data) => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.TASK_HEADER + "/" + data?._id, { headers: getHeaders() })
            .then((resp) => {
                setSubTaskList(resp?.data?.data[0]?.subTasks);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getUserList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
            .then((resp) => {
                setUserList(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const defaultValues = { subTaskName: "", subTaskDescription: "", subTaskKeyPointers: "", assignTo: "", fileAttachment: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    useEffect(() => {
        if (subTaskEdit) {
            setValue("subTaskName", subTaskEdit?.subTaskName)
            setValue("subTaskDescription", subTaskEdit?._id?.subTaskDescription)
            setValue("subTaskKeyPointers", subTaskEdit?._id?.subTaskKeyPointers)
            setValue("assignTo", subTaskEdit?._id?.assignTo?._id)
            setValue("fileAttachment", subTaskEdit?._id?.fileAttachment === true ? "Yes" : "No")
        }
    }, [subTaskEdit]);

    const onSubmitTask = (data) => {
        const payload = {
            subTaskName: data.subTaskName,
            subTaskDescription: data.subTaskDescription,
            subTaskKeyPointers: data.subTaskKeyPointers, // selectedrow?._id
            assignTo: data.assignTo,
            fileAttachment: data.fileAttachment === "Yes" ? true : false,
            taskHeader_id: tochildSubTask?._id, //"pjt_01hjnhmmf5fjprsz3zpf25m2kf",
        }
        console.log(payload);
        setIsLoading(true);
        if (subTaskEdit) {
            axios.patch(constants.APQP_UPL.SUB_TASKS + "/" + subTaskEdit?._id?._id, payload, { headers: getHeaders() })
                .then((resp) => {
                    // console.log(resp?.data?.data);
                    getProjectTaskHeader();
                    toast.current.show({ severity: "success", summary: "Success", detail: "Updated Successfully" });
                    reset();
                    getSubTask(tochildSubTask);
                    setSubTaskEdit(null);
                }).catch((e) => {
                    toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })
        } else {
            axios.post(constants.APQP_UPL.SUB_TASKS, payload, { headers: getHeaders() })
                .then((resp) => {
                    // console.log(resp?.data?.data);
                    getProjectTaskHeader();
                    toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully" });
                    reset();
                    getSubTask(tochildSubTask);
                }).catch((e) => {
                    toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const handleTaskDelete = (data) => {
        console.log(data);
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.TASK_HEADER + "/" + data?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                getProjectTaskHeader();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        console.log(item);
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const handleSubTaskDelete = (data) => {
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.SUB_TASKS + "/" + data?._id?._id, { headers: getHeaders() })
            .then((resp) => {
                getSubTask(tochildSubTask);
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }
    
    const confirmSubTask = (item) => {
        console.log(item);
        confirmDialog({
            message: 'Do you want to delete this Sub Task?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleSubTaskDelete(item),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const header = (
        <div className="head-1">
            <h2 className="head-2">Add Sub Task</h2>
        </div>
    );

    const customButtonTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                <div><Button className="btn-black" label="Sub Task" icon="pi pi-plus" onClick={() => { setTaskScreen(true); getSubTask(item); setTochildSubTask(item); setSubTaskEdit(null); reset(); }} /></div>
                <i className="pi pi-pencil" onClick={() => { setViewScreen(true); setTochild(item) }} />
                <i className="pi pi-trash" onClick={() => confirm(item)} />
            </div>
        );
    };

    const customSubTaskButtonTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                <i className="pi pi-pencil" onClick={() => setSubTaskEdit(item)} />
                <i className="pi pi-trash" onClick={() => confirmSubTask(item)} />
            </div>
        );
    };

    const formatDate = (value) => {
        var date = new Date(value?.expected_edate);
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            {!viewScreen && !taskScreen ?
                <div className='card'>
                    <div className="">
                        <div className="PS-title pt-2">Task Definition</div>
                        <div className="btn2">
                            <div>
                                <Button className="mr-4" label="Task Header" icon="pi pi-plus" onClick={() => setViewScreen(true)} /></div>
                        </div>
                    </div>
                    <hr className="hr-line"></hr>
                    <div className="mt-4">
                        <DataTable className="" value={records} responsiveLayout="scroll">
                            <Column field="headerID" header="Header ID"></Column>
                            <Column field="headerTitle" header="Title"></Column>
                            <Column field="type" header="Type"></Column>
                            <Column field="headerDescription" header="Description"></Column>
                            <Column field="subTaskCount" header="Sub Tasks"></Column>
                            <Column header="" body={customButtonTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
                : (viewScreen && !taskScreen) ?
                    <div className='card'>
                        <div className="cursor-pointer mt-2" onClick={() => {setViewScreen(false); getProjectTaskHeader(); setTochild(null)}}>
                            <img src={back} className="back-logo mr-2" />
                            <span className="PS-title pt-2">Definition / Add a Task Header</span>
                        </div>
                        <hr className="hr-line"></hr>
                        <ProjectTaskHeader tochild={tochild} />
                    </div>
                    : taskScreen && !taskView ?
                        <div className='card'>
                            <div className="cursor-pointer mt-2" onClick={() => { setViewScreen(false); setTaskScreen(false); setTaskView(false); getProjectTaskHeader(); }}>
                                <img src={back} className="back-logo mr-2" />
                                <span className="PS-title pt-2">Definition / {tochildSubTask?.headerTitle}</span>
                            </div>
                            <hr className="hr-line"></hr>
                            <div className="grid">
                                <div className="col-12">
                                    <Card header={header}>
                                        <form onSubmit={form.handleSubmit(onSubmitTask)} className="error_msg">
                                            <div className="p-fluid formgrid grid">
                                                <div className='col-12 lg:col-6'>
                                                    <div className="field col-12">
                                                        <label htmlFor="subTaskName" className="col-6">
                                                            Task Name
                                                        </label><span className="w-full">
                                                            <Controller name="subTaskName" control={form.control}
                                                                rules={{ required: "Task Name is required" }}
                                                                render={({ field, fieldState }) => (
                                                                    <InputText id='subTaskName' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                                        placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                                )}
                                                            />
                                                            {getFormErrorMessage("subTaskName")}</span>
                                                    </div>
                                                    {/* <div className="field col-12">
                                                        <label htmlFor="taskId" className="col-6">
                                                            Task ID
                                                        </label><span className="w-full">
                                                            <Controller name="taskId" control={form.control}
                                                                rules={{ required: "Task ID is required" }}
                                                                render={({ field, fieldState }) => (
                                                                    <InputText id='taskId' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                                        placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                                )}
                                                            />
                                                            {getFormErrorMessage("taskId")}</span>
                                                    </div> */}
                                                    <div className="field col-12">
                                                        <label htmlFor="subTaskKeyPointers" className="col-6">
                                                            Task Key Pointers
                                                        </label><span className="w-full">
                                                            <Controller name="subTaskKeyPointers" control={form.control}
                                                                rules={{ required: "Task Key Pointers is required" }}
                                                                render={({ field, fieldState }) => (
                                                                    <InputText id='subTaskKeyPointers' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                                        placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                                )}
                                                            />
                                                            {getFormErrorMessage("subTaskKeyPointers")}</span>
                                                    </div>
                                                    <div className="field col-12">
                                                        <label htmlFor="assignTo" className="col-6">
                                                            Assign To
                                                        </label><span className="w-full">
                                                            <Controller name="assignTo" control={form.control}
                                                                rules={{ required: "Assign To is required" }}
                                                                render={({ field, fieldState }) => (
                                                                    <Dropdown id='assignTo' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                                        options={userList} optionLabel="first_name" optionValue='_id' placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                                )}
                                                            />
                                                            {getFormErrorMessage("assignTo")}</span>
                                                    </div>
                                                </div>
                                                <div className='col-12 lg:col-6'>
                                                    <div className="field col-12 align-items-start">
                                                        <label htmlFor="subTaskDescription" className="col-6 mt-3">
                                                            Task Description
                                                        </label><span className="w-full">
                                                            <Controller name="subTaskDescription" control={form.control}
                                                                rules={{ required: "Description is required" }}
                                                                render={({ field, fieldState }) => (
                                                                    <InputTextarea rows={5} id='subTaskDescription' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                                        placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                                )}
                                                            />
                                                            {getFormErrorMessage("subTaskDescription")}</span>
                                                    </div>
                                                    <div className="field col-12">
                                                        <label className="col-6">File Attachment</label>
                                                        <span className="w-full">
                                                            <Controller name="fileAttachment" control={form.control}
                                                                rules={{ required: "File Attachment is required" }}
                                                                render={({ field, fieldState }) => (
                                                                    <div className="flex flex-wrap gap-2">
                                                                        <div className="flex align-items-center">
                                                                            <RadioButton inputId="fullday" name="fileAttachment" value="Yes" onChange={(e) => field.onChange(e.value)} checked={field.value === 'Yes'} />
                                                                            <label htmlFor="fullday" className="ml-2">Yes</label>
                                                                        </div>
                                                                        <div className="flex align-items-center">
                                                                            <RadioButton inputId="halfday" name="fileAttachment" value="No" onChange={(e) => field.onChange(e.value)} checked={field.value === 'No'} />
                                                                            <label htmlFor="halfday" className="ml-2">No</label>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                            {getFormErrorMessage("fileAttachment")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-content-end">
                                                <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                                            </div>
                                        </form>
                                    </Card>
                                </div>
                            </div>
                            
                            <div className="cursor-pointer mt-4" onClick={() => { setViewScreen(false); setTaskScreen(false); setTaskView(false) }}>
                                <span className="PS-title pt-2">Sub Task List</span>
                            </div>
                            <hr className="hr-line"></hr>
                    <div className="mt-4">
                        {subTaskList?.map((item) => {
                            return (
                                <div className="card flex">
                                    <div className='col-3 PS-card-key'>
                                        <h4>Task ID</h4>
                                        <p>{item?._id?.subTaskID}</p>
                                    </div>
                                    <div className='col-2 PS-card-key'>
                                        <h4>Title</h4>
                                        <p>{item?.subTaskName}</p>
                                    </div>
                                    <div className='col-2 PS-card-key text-center'>
                                        <h4>Assigned to</h4>
                                        <p>{item?._id?.assignTo?.first_name}</p>
                                    </div>
                                    <div className='col-2 PS-card-key text-center'>
                                        <h4>File Attachements</h4>
                                        <p>{item?._id?.fileAttachment === true ? "Yes" : "No"}</p>
                                    </div>
                                    <div className='col-3 PS-card-key'>
                                        <div>{customSubTaskButtonTemplate(item)}</div>
                                    </div>
                                </div>
                            )
                        })}
                        {subTaskList && subTaskList?.length > 0 ? null : "No Data found"}
                    </div>
                        </div>
                        : null
            }
        </div>
    )
}
export default ProjectsDefinition
