import React, { useEffect } from "react";
// import "../style/punch.css";
import EmployeeTable from "./EmployeeTable";
import EmployeeAdd from "./EmployeeAdd";
import { TabMenu } from 'primereact/tabmenu';
import { Route, useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";

const Employee = () => {
    const history = useHistory();
    useEffect(() => {
        return setActiveIndex(0)
    }, []);
    
    // const [activeIndex, setActiveIndex] = useState(0);
    const setActiveIndex = useStoreActions((actions) => actions.employeeModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.employeeModel.activeIndex);
    // const tabItems = [
    //     {label: 'All Employee', command: () => history.push('/app/defaultnav')},
    //     {label: 'Add Employee', command: () => history.push('/app/defaultnav/employeeadd') },
    // ];

    const tabItems = [
        { label: 'All Employee' },
        { label: 'Add Employee' },
    ];

    return (
        <>
        <div className="card">
            <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            {/* <Route exact path="/app/defaultnav" component={EmployeeTable}></Route>
            <Route path="/app/defaultnav/employeeadd" component={EmployeeAdd}></Route> */}
                {activeIndex === 0 && <EmployeeTable />}
                {activeIndex === 1 && <EmployeeAdd />}
        </div>
    </>
                        
    );
};

export default Employee;
