import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../constants/constants';
import "../../css/projects.css"
import { Button } from "primereact/button";
import getHeaders from '../../constants/utils';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AddProjects from './AddProjects';
import back from "../../Images/projects/black.svg";
import ProjectView from './ProjectView';
import { ProgressBar } from 'primereact/progressbar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const Projects = () => {
    const toast = useRef(null);
    const [records, setRecords] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [addProjects, setAddProjects] = useState(false);
    const setProjectViewDatas = useStoreActions((actions) => actions.projectsModel.setProjectViewDatas);
    const setProjectViewScreen = useStoreActions((actions) => actions.projectsModel.setProjectViewScreen);
    const projectViewScreen = useStoreState((state) => state.projectsModel.projectViewScreen);

    useEffect(() => {
        setIsLoading(true);
        getAllProjectsData();
        setProjectViewScreen(false);
    }, []);

    useEffect(() => {
        getAllProjectsData();
    }, [!projectViewScreen]);

    const getAllProjectsData = () => {
        axios.get(constants.APQP_UPL.PROJECTS, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleView = (item) => {
        setProjectViewDatas(item);
        setProjectViewScreen(true);
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const handleStart = (item) => {
        const payload = {
            projectStatus: "In-Progress",
            // taskHeader_id: ["tshd_01hwmc4ad9endr37gdf211d0zd"]
        }
        setIsLoading(true);
        axios.patch(constants.APQP_UPL.PROJECTS + "/" + item?._id, payload, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                getAllProjectsData();
                toast.current.show({ severity: "success", summary: "Success", detail: "Project Started Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleTaskDelete = (item) => {
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.PROJECTS + "/" + item?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                getAllProjectsData();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        console.log(item);
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const customButtonTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                <Button className="btn-black" label={item?.projectStatus !== "Open" ? "Started" : "Start"} disabled={item?.projectStatus !== "Open"} onClick={() => handleStart(item)} />
                <Button className="btn-black" label="View" onClick={() => handleView(item)} />
                <Button className="btn-black" label="Delete" onClick={() => confirm(item)} />
            </div>
        );
    };

    const progressBarTemplate = (item) => {
        return (
            <div className='PS-card-key text-center'>
                <p>{item?.completedPercentage}%</p>
                <ProgressBar showValue={false} value={item?.completedPercentage} style={{ height: '6px' }} pt={{ value: { style: { background: '#26C10D' } } }}></ProgressBar>
            </div>
        );
    };

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const findMinStartDate = (record) => {
        let minStartDate = null;

        // Iterate through each task within subTasks
        record.taskHeader_id.forEach(taskHeader => {
            taskHeader.subTasks.forEach(task => {
                // Convert startDate to Date object
                if (task.startDate) {
                    let startDate = new Date(task.startDate);
                    // Update minStartDate if it's null or the current startDate is earlier
                    if (!minStartDate || startDate < minStartDate) {
                        minStartDate = startDate;
                    }
                }
            });
        });

        return formatDate(minStartDate);
    };

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            {!addProjects && !projectViewScreen ?
                <div className='card'>
                    <div className="flex align-items-center justify-content-between mt-2">
                        <h3 className="PS-title pt-2">All Project</h3>
                        <Button icon="pi pi-plus" className="btn-black" label="Project" onClick={() => setAddProjects(true)} />
                    </div>
                    <hr className='hr-line'></hr>
                    <div className="grid mt-3">
                        <div className="col-12">
                        <DataTable className="" value={records} responsiveLayout="scroll">
                            <Column field="projectName" header="Project Name"></Column>
                            <Column field="client_id.customerName" header="Client Name"></Column>
                            <Column field="" header="Start Date" body={(rowData)=> findMinStartDate(rowData)}></Column>
                            <Column field="projectEndDate" header="End Date" body={(rowData)=> formatDate(rowData.projectEndDate)}></Column>
                            <Column field="completedPercentage" header="Completed Status"></Column>
                            <Column field="" header="Status" body={progressBarTemplate}></Column>
                            <Column header="" body={customButtonTemplate}></Column>
                        </DataTable>
                        </div>
                    </div>
                </div>
                :
                addProjects && !projectViewScreen ?
                    <div className='card'>
                        <div className="flex align-items-center mt-2 cursor-pointer" onClick={() => { setAddProjects(false); getAllProjectsData() }}>
                            <img src={back} className="back-logo mr-2" />
                            <h3 className="PS-title m-0">All Project / Add Project</h3>
                        </div>
                        <hr className="hr-line"></hr>
                        <AddProjects />
                    </div>
                    :
                    <div className='card'>
                        <ProjectView />
                    </div>
            }
        </div>
    )
}
export default Projects