// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PENDING {
  color: var(--primary-color);
}

.APPROVED {
  color: #12b66a;
}

.DENIED {
  color: #b61212;
}

.leave_btn {
  border: none;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,cAAA;AAGJ;;AADA;EACA,YAAA;EACA,kBAAA;AAIA","sourcesContent":[".PENDING{\n    color: var(--primary-color)\n}\n.APPROVED{\n    color: #12b66a;\n}\n.DENIED{\n    color: #b61212;\n}\n.leave_btn{\nborder: none;\nborder-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
