import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import constants from "../constants/constants";
import axios from "axios";
import getHeaders from "../constants/utils";

const EmployeeTable = () => {
    const history = useHistory();
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const setActiveIndex = useStoreActions((actions) => actions.employeeModel.setActiveIndex);
    const setEmployeeEditValue = useStoreActions((actions) => actions.employeeModel.setEmployeeEditValue);

    useEffect(() => {
        getEmployeeList();
        setEmployeeEditValue(null);
    }, []);

    const getEmployeeList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.EMPLOYEE_LIST, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setRecords(resp.data.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    // const handleClick = (data) => {
    //     history.push({
    //         pathname: "/App/defaultnav/employeeadd",
    //         state: { myData: data },
    //     });
    //     setActiveIndex(1);
    // };
    

    const handleTaskDelete = (item) => {
        setIsLoading(true);
        axios.delete(constants.URL.EMPLOYEE_LIST + "/" + item?._id, { headers: getHeaders() })
            .then((resp) => {
                getEmployeeList();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            draggable: false,
        });
    };

    const IconBodyTemplate = (selectedEmployeeDetail) => {
        // console.log("selectedEmployeeDetail",selectedEmployeeDetail);
        return (
            <div>
                <i onClick={() => {setActiveIndex(1); setEmployeeEditValue(selectedEmployeeDetail)}} className="pi pi-pencil mr-4"></i>
                <i className="pi pi-trash" onClick={() => confirm(selectedEmployeeDetail)} />
            </div>
        );
    };

    return (
        <div>
        <Toast ref={toast} />
        <ConfirmDialog />
            <div className="grid mt-3">
                <div className="col-12">
                    <div className="card">
                        <DataTable value={records} responsiveLayout="scroll">
                            <Column field="first_name" header="Name"></Column>
                            {/* <Column field="employeeno" header="Employee No" style={{ minWidth: "200px" }}></Column> */}
                            <Column field="email" header="Email ID"></Column>
                            <Column field="mobile_no" header="Mobile Number"></Column>
                            {/* <Column field="DOB" header="Date Of Birth" style={{ minWidth: "200px" }}></Column> */}
                            <Column header="" body={IconBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeTable;
