import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./style/Header.css";
import { Image } from "primereact/image";
import { Menu } from "primereact/menu";
import { useStoreActions, useStoreState } from "easy-peasy";
import getHeaders from "./constants/utils";
import { Toast } from "primereact/toast";
import constants from "./constants/constants";
import axios from "axios";

export const AppTopbar = (props) => {
    const menu = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    let history = useHistory();
    const setActiveIndex = useStoreActions((actions) => actions.employeeModel.setActiveIndex);
    const logOut = useStoreActions((actions) => actions.loginModel.logOut);
    const user = useStoreState((state) => state.loginModel.user);

    let items = [
        // {
        //     label: "Refer friends",
        //     icon: "pi pi-fw pi-share-alt",
        // },
        // {
        //     label: "Privacy policy",
        //     icon: "pi pi-fw pi-lock",
        // },
        // {
        //     label: "Account settings",
        //     icon: "pi pi-fw pi-cog",
        // },
        {
            label: "Logout",
            icon: "pi pi-fw pi-power-off",
            command: () => {
                // signout();
                setActiveIndex(0);
                logOut();
                goto("/");
            },
        },
    ];

    // const signout = () => {
    //     setIsLoading(true);
    //     axios
    //         .post(constants.URL.SIGNOUT, "", {
    //             headers: getHeaders(),
    //         })
    //         .then((resp) => {
    //             console.log(resp);
    //         })
    //         .catch((e) => {
    //             toast.current.show({ severity: "error", summary: "Error", detail: "Failed to Sign Out" });
    //             console.error(e);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // };

    const goto = (to) => {
        history.replace(to);
    };

    return (
        <div className="layout-topbar">
            <Menu model={items} popup ref={menu} />
            <Toast ref={toast} />

            <button type="button" className="p-link  layout-menu-button layout-topbar-button mr-1" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>
            <div className="layout-topbar-logo">
                <Image src="/images/logo.png"></Image>

                {/* <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/> */}
            </div>

            <div className="logo-link ms-sm-5 cursor-pointer" onClick={(e) => menu.current.toggle(e)}>
                <Image className="rightLogo mr-2" height="30" width="30" src="/images/header-right.png"></Image>
                <span className="header-name pr-2">{user?.name}</span>
                <i className="pi pi-angle-down mr-4"></i>
            </div>

            <button type="button" style={{ visibility: "hidden" }} className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                {/* <i className="pi pi-ellipsis-v" /> */}
            </button>
        </div>
    );
};
