import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import constants from '../../constants/constants';
import getHeaders from '../../constants/utils';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const LeaveConfigSetting = () => {
    const history = useHistory();
    const toast = useRef(null);
    const [records, setRecords] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [editValue, setEditValue] = useState();
    const [visible, setVisible] = useState(false);
    const [typeList, setTypeList] = useState();
    const [categoryList, setCategoryList] = useState();
    const [leaveCategory, setLeaveCategory] = useState();
    const [visibleCompensation, setVisibleCompensation] = useState(false);
    const user = useStoreState((actions) => actions.loginModel.user);

    const validityList = [
        { label: "1 Month", value: 1 },
        { label: "2 Month", value: 2 },
        { label: "3 Month", value: 3 },
        { label: "4 Month", value: 4 },
        { label: "5 Month", value: 5 },
        { label: "6 Month", value: 6 },
        { label: "7 Month", value: 7 },
        { label: "8 Month", value: 8 },
        { label: "9 Month", value: 9 },
        { label: "10 Month", value: 10 },
        { label: "11 Month", value: 11 },
        { label: "12 Month", value: 12 },
    ];

    useEffect(() => {
        setIsLoading(true);
        getLeaveConfig();
        getType();
    }, []);

    const getType = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.ADD_TYPES_IN_LEAVE_CONFIG + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                setCategoryList(resp?.data?.results);
                setTypeList(resp?.data?.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getLeaveConfig = () => {
        axios.get(constants.URL.LEAVE_CONFIG_LIST + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                setRecords(resp?.data?.results);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const IconHeaderTemplate = () => {
        return (
            <div>
                <i className="pi pi-plus-circle mr-4" onClick={() => setVisible(true)}></i>
            </div>
        );
    };

    const handleDelete = (item) => {
        setIsLoading(true);
        axios.delete(constants.URL.ADD_LEAVE_CONFIG + "/" + item?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                getLeaveConfig();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        console.log(item);
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleDelete(item),
            draggable: false,
        });
    };

    const IconBodyTemplate = (item) => {
        if(item.type === "Compensation"){
            return (
                <div>
                    <i className="pi pi-pencil mr-4" onClick={() => {handleViewCompensation(item)}}></i>
                    <i className="pi pi-trash" label="Delete" onClick={() => confirm(item)} />
                </div>
            );
        } else return (
            <div>
                <i className="pi pi-pencil mr-4" onClick={() => {handleView(item)}}></i>
                <i className="pi pi-trash" label="Delete" onClick={() => confirm(item)} />
            </div>
        );
    };

    const defaultValues = { type: "", category: "", allowed: "", limit: "", restrictions: "", validity: "", maxmin: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (values) => {
        const data = {
            org_id: user?.org_id?._id,
            category: values.category,
            allowed: values.allowed,
            type: values.type,
            restrictions: values.restrictions,
            limit: values.limit,
            validity: values.validity,
            maxmin: values.maxmin
        };
        console.log(data);

        setIsLoading(true);
        if(!editValue){
            axios
                .post(constants.URL.ADD_LEAVE_CONFIG, data, {
                    headers: getHeaders(),
                })
                .then((resp) => {
                    toast.current.show({ severity: "success", summary: "Success", detail: "Leave Configured Successfully" });
                    getLeaveConfig();
                    handleClose();
                })
                .catch((e) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: e.response.data.message });
                    console.error(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });

        }else {
        axios
        .patch(constants.URL.ADD_LEAVE_CONFIG + "/" + editValue?._id, data, {
            headers: getHeaders(),
        })
        .then((resp) => {
            toast.current.show({ severity: "success", summary: "Success", detail: "Leave Configured Successfully" });
            getLeaveConfig();
            handleClose();
        })
        .catch((e) => {
            toast.current.show({ severity: "error", summary: "Error", detail: e.response.data.message });
            console.error(e);
        })
        .finally(() => {
            setIsLoading(false);
        });
        }
    };

    useEffect(() => {
        if (editValue) {
            setValue("type", editValue?.type)
            setValue('category', editValue?.category)
            setValue('limit', editValue?.limit)
            setValue('restrictions', editValue?.restrictions)
            setValue('allowed', editValue?.allowed)
            setValue('validity', editValue?.validity)
            setValue('maxmin', editValue?.maxmin)
        }
    }, [editValue]);
    console.log(editValue, defaultValues);
    
    const handleView = (item) => {
        console.log(item);
        setEditValue(item)
        setVisible(true)
    }
    
    const handleViewCompensation = (item) => {
        console.log(item);
        setEditValue(item)
        setVisibleCompensation(true)
    }

    const handleClose = () => { setVisible(false); setEditValue(); reset(); setVisibleCompensation(false) }

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="card mt-3">
                <DataTable loading={isLoading} value={records}>
                    <Column field="category" header="Leave Category"></Column>
                    <Column field="type" header="Leave Type"></Column>
                    <Column field="allowed" header="Allowed"></Column>
                    <Column field="limit" header="Limit"></Column>
                    <Column field="restrictions" header="Restrictions"></Column>
                    <Column field="maxmin" header="Max/Min"></Column>
                    <Column field="" header={IconHeaderTemplate} body={IconBodyTemplate}></Column>
                </DataTable>
            </div>
            <Dialog header="Add" visible={visible} style={{ width: "40vw" }} onHide={() => handleClose()}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                    <div className="formgrid grid ">

                        <div className="field col-12 md:col-6">
                            <label htmlFor="category">
                                Leave Category<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="category"
                                control={form.control}
                                rules={{ required: "Category is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={Array.from(new Set(categoryList?.map((el) => el.leaveCategory)))} value={field.value} placeholder="Select a Category" name="category" control={form.control} onChange={(e) => {field.onChange(e.value); setLeaveCategory(e.value); setValue("type", "")}} className={classNames({ "p-invalid w-full": fieldState.error })} />
                                )}
                            />
                            {getFormErrorMessage("category")}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="type">
                                Leave Type<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="type"
                                control={form.control}
                                rules={{ required: "Type is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown value={field.value} placeholder="Select a Type" name="type" options={typeList?.filter((el) => el.leaveCategory === leaveCategory)} optionLabel='leaveTypes' optionValue='leaveTypes' control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} />
                                )}
                            />
                            {getFormErrorMessage("type")}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="allowed">
                                Allowed<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="allowed"
                                control={form.control}
                                rules={{ required: "Allowed is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText keyfilter="int" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="limit">
                                Limit<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="limit"
                                control={form.control}
                                rules={{ required: "Limit is required." }}
                                render={({ field, fieldState }) => (
                            <Dropdown options={["Month", "Year"]} id="limit" value={field.value} control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} />
                        )}
                        />
                        {getFormErrorMessage("limit")}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="restrictions">
                                Restrictions<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="restrictions"
                                control={form.control}
                                rules={{ required: "Restrictions is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText keyfilter="int" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} placeholder='No.of.Continuous' />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="maxmin">
                                Max / Min<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="maxmin"
                                control={form.control}
                                rules={{ required: "Max / Min is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={["max", "min"]} value={field.value} name="maxmin" control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} />
                                )}
                            />
                            {getFormErrorMessage("maxmin")}
                        </div>
                    </div>
                    <div className="flex justify-content-end">
                        <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" />
                    </div>
                </form>
            </Dialog>
            <Dialog header="Edit Compensation" visible={visibleCompensation} style={{ width: "40vw" }} onHide={() => handleClose()}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                    <div className="formgrid grid ">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="validity">
                                Validity<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="validity"
                                control={form.control}
                                rules={{ required: !editValue ? false : "Validity is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                            <Dropdown options={validityList} id="validity" value={field.value} control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} />
                            {getFormErrorMessage("validity")}
                            </>
                        )}
                        />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="restrictions">
                                Restrictions<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="restrictions"
                                control={form.control}
                                rules={{ required: "Restrictions is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} placeholder='No.of.Continuous' />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="maxmin">
                                Max / Min<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="maxmin"
                                control={form.control}
                                rules={{ required: "Max / Min is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={["max", "min"]} value={field.value} name="maxmin" control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} />
                                )}
                            />
                            {getFormErrorMessage("maxmin")}
                        </div>
                    </div>
                    <div className="flex justify-content-end">
                        <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" />
                    </div>
                </form>
            </Dialog>
        </div>
    )
}

export default LeaveConfigSetting