// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  /* padding: 2px 15px 2px 20px;
  box-shadow: 0 12px 44px -10px white;
  background-color: white; */
}
.p-link{
  display: flex;
  align-items: center;
}
.header-name{
  color: #242424;
}
.logo-link{
  position: absolute;
  right: 0;
  align-items: center;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/style/Header.css"],"names":[],"mappings":"AAAA;EACE;;4BAE0B;AAC5B;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".header {\n  /* padding: 2px 15px 2px 20px;\n  box-shadow: 0 12px 44px -10px white;\n  background-color: white; */\n}\n.p-link{\n  display: flex;\n  align-items: center;\n}\n.header-name{\n  color: #242424;\n}\n.logo-link{\n  position: absolute;\n  right: 0;\n  align-items: center;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
