import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import getHeaders from "../constants/utils";
import constants from "../constants/constants";
import axios from "axios";
import "../App.css";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import down from "../Images/down-icon.svg";
import { saveAs } from "file-saver";

const PunchTable = () => {
    const toast = useRef(null);
    const user = useStoreState((state) => state.loginModel.user);
    const setSession = useStoreActions((actions) => actions.loginModel.setSession);
    const session = useStoreState((state) => state.loginModel.session);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    const dt = useRef(null);

    const filterSessionData = () => {
        if (fromDate && toDate) {
            const filteredData = session.filter((s) => {
                const sessionDate = new Date(s.createdAt);
                return sessionDate >= fromDate && sessionDate <= toDate;
            });
            return filteredData;
        }
        return session;
    };

    const exportCSV = () => {
        const filteredData = filterSessionData();
        if (filteredData.length === 0) {
            toast.current.show({ severity: "warn", summary: "No Data", detail: "No data available for the selected date range." });
            return;
        }
    
        const csvContent =
            "Date,Day,In Time,Out Time,Task Description\n" +
            filteredData
                .map((row) => {
                    const date = new Date(row.createdAt).toLocaleDateString();
                    const day = new Date(row.createdAt).toLocaleString("en-US", { weekday: "long" });
                    const inTime = row.in_time ? new Date(row.createdAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "";
                    let outTime = row.out_time ? new Date(row.updatedAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "";
                    outTime = outTime === "05:30 AM" ? "12:00 AM" : outTime;
                    
                    const task = row.task ? `"${row.task.replace(/"/g, '""').replace(/\n/g, ' ').replace(/\r/g, ' ')}"` : "";
                    return `${date},${day},${inTime},${outTime},${task}`;
                })
                .join("\n");
    
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "session-data.csv");
    };
    useEffect(() => {
        getSession();
    }, []);

    const getSession = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.SESSION, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setSession(resp.data.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const punchIn = () => {
        setIsLoading(true);
        axios
            .post(constants.URL.PUNCH_IN, "", {
                headers: getHeaders(),
            })
            .then((resp) => {
                toast.current.show({ severity: "success", summary: "Success", detail: "Punched In Successfully" });
                getSession();
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Punch In failed" });
                console.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const defaultValues = { task: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (data) => {
        const payload = {
            task: data.task,
        };
        setIsLoading(true);
        axios
            .post(constants.URL.PUNCH_OUT, payload, {
                headers: getHeaders(),
            })
            .then((resp) => {
                toast.current.show({ severity: "success", summary: "Success", detail: "Punched Out Successfully" });
                getSession();
                reset();
                setVisible(false);
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Punch Out Failed" });
                console.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="card punch-table">
                <div className="flex justify-content-end">
                    <div className="cal-head">
                        <Calendar name="from" value={fromDate} style={{ width: "135px", paddingRight: "1rem" }} onChange={(e) => setFromDate(e.value)} placeholder="From" dateFormat="dd/mm/yy" showIcon />
                        <Calendar name="To" value={toDate} style={{ width: "135px", paddingRight: "1rem" }} onChange={(e) => setToDate(e.value)} placeholder="To" dateFormat="dd/mm/yy" showIcon />

                        <img src={down} alt="clanizon" className="down-icon" onClick={exportCSV} />
                    </div>
                    {(session && session?.length > 0 && session[0].in_time && session[0].out_time) || (session && session?.length === 0) ? <Button label="Punch In" onClick={punchIn} className="mb-3" /> : <Button label="Punch Out" onClick={() => setVisible(true)} className="mb-3" />}
                </div>
                <DataTable value={filterSessionData()} responsiveLayout="scroll" ref={dt}>
                    <Column
                        field="createdAt"
                        header="Date"
                        body={(rowData) => {
                            const date = new Date(rowData.createdAt);
                            return date.toLocaleDateString();
                        }}
                    ></Column>
                    <Column
                        field="createdAt"
                        header="Day"
                        body={(rowData) => {
                            const date = new Date(rowData.createdAt);
                            return date.toLocaleString("en-US", { weekday: "long" });
                        }}
                    ></Column>
                    {/* <Column field="in_time" header="In time" body={(rowData) => {
            if (rowData.in_time === null) {
              return "";
            } else {
              const date = new Date(rowData.in_time);
              const inTime = date.toLocaleTimeString();
              return inTime;
            }
          }}></Column> */}
                    <Column
                        field="in_time"
                        header="In time"
                        body={(rowData) => {
                            if (rowData.in_time === null) {
                                return "";
                            } else {
                                const date = new Date(rowData.createdAt);
                                const inTime = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
                                return inTime;
                            }
                        }}
                    ></Column>
                    <Column
                        field="out_time"
                        header="Out time"
                        body={(rowData) => {
                            if (rowData.out_time === null) {
                                return "";
                            } else {
                                const date = new Date(rowData.updatedAt);
                                const outTime = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
                                return outTime === "05:30 AM" ? "12:00 AM" : outTime;
                            }
                        }}
                    ></Column>
                    <Column field="task" header="Task Description"></Column>
                </DataTable>
                <Dialog
                    header="Add"
                    visible={visible}
                    style={{ width: "30vw" }}
                    breakpoints={{ "960px": "60vw", "641px": "80vw" }}
                    onHide={() => {
                        setVisible(false);
                        reset();
                    }}
                >
                    <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                        <div className="formgrid grid">
                            <div className="field col-12">
                                <label>Task Description</label>
                                <span className="w-full">
                                    <Controller
                                        name="task"
                                        control={form.control}
                                        rules={{ required: "Task Description is required.", validate: (value) => value.trim() !== "" || "Task Description cannot be just empty spaces." }}
                                        render={({ field, fieldState }) => <InputTextarea rows={3} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error }, "w-full")} onChange={(e) => field.onChange(e.target.value)} />}
                                    />
                                    {getFormErrorMessage("task")}
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-content-end">
                            <Button loading={isLoading} className="leave_btn" label="Add" />
                        </div>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

export default PunchTable;
