import "react-app-polyfill/ie11";
import React, { useEffect } from "react";
import App from "./App";
import { HashRouter, Route, Switch, useHistory } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { useStoreState, useStoreActions } from "easy-peasy";
import Login from "./pages/Login";
import SignUp from "./SignUp";
import Resetpwd from "./Resetpwd";
import Forgetpwd from "./Forgetpwd";
import CustomRoute from "./CustomRoute";

function Main() {
    const user = useStoreState((state) => state.loginModel.user);
    const setUser = useStoreActions((actions) => actions.loginModel.setUser);
    const history = useHistory();

    const INACTIVITY_THRESHOLD = 30 * 60 * 1000; // 30 minutes (in milliseconds)

    let inactivityTimer = null;
    let lastActivityTimestamp = Date.now();

    // Reset the inactivity timer on every user interaction
    useEffect(() => {
        document.addEventListener('mousemove', resetInactivityTimer);
        document.addEventListener('keypress', resetInactivityTimer);

        // Clean up event listeners on component unmount
        return () => {
            document.removeEventListener('mousemove', resetInactivityTimer);
            document.removeEventListener('keypress', resetInactivityTimer);
            if (inactivityTimer) {
                clearTimeout(inactivityTimer);
            }
        };
    }, []);

    function resetInactivityTimer() {
        lastActivityTimestamp = Date.now();

        // Clear the existing timer
        if (inactivityTimer) {
            clearTimeout(inactivityTimer);
        }

        // Start a new timer
        inactivityTimer = setTimeout(logoutUser, INACTIVITY_THRESHOLD);
    }

    function logoutUser() {
        console.log('Logging out user due to inactivity.');
        setUser([]);

        if (window.location.pathname !== "/") {
            history.push('/');
        }
    }

    // Start the inactivity timer
    useEffect(() => {
        resetInactivityTimer();
    }, []);

    return (
        <HashRouter>
            <Switch>
                <ScrollToTop>
                    <Route exact path="/" component={Login} />
                    <Route path="/signup" component={SignUp} />
                    <Route path="/resetPassword" component={Resetpwd} />
                    <Route path="/forgetPassword" component={Forgetpwd} />
                    <CustomRoute
                        path="/app" component={App}
                        allowedRoles={["EMPLOYEE", "SU_ADMIN", "PAYSLIP_ADMIN", "EMPLOYEE_ADMIN", "CONFIG_ADMIN"]}
                        currentUserRoles={user?.permissions}
                    />
                </ScrollToTop>
            </Switch>
        </HashRouter>
    );
}

export default Main;
