import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useStoreState } from 'easy-peasy';

const LeaveTable = () => {
    const [details, setDetails] = useState();
    const leaveDetails = useStoreState((state) => state.leaveModel.leaves);
    const employeeDetails = useStoreState((actions) => actions.employeeModel.employeeDetails);

    useEffect(() => {
        setDetails(leaveDetails);
    }, [leaveDetails]);

    return (
        <div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable className='' value={details} responsiveLayout="scroll">
                            <Column field="from_date" header="Leave From" body={(rowData) => {
                                const date = new Date(rowData.from_date);
                                return date.toLocaleDateString();
                            }}
                            ></Column>
                            <Column field="to_date" header="Leave To" body={(rowData) => {
                                const date = new Date(rowData.to_date);
                                return date.toLocaleDateString();
                            }}
                            ></Column>
                            <Column field="type" header="Type" ></Column>
                            <Column field="no_of_days" header="No of Days" ></Column>
                            <Column field="reason" header="Reason" ></Column>
                            <Column field="manager" header="Manager" body={(rowData) => {
                                const getReportingPerson = employeeDetails?.find((item) => item?._id === rowData?.manager);
                                return getReportingPerson?.first_name;
                            }} ></Column>
                            <Column field="status" header="Status" body={(rowData) => {
                                let status = rowData.status
                                return <span className={status}>{status.charAt(0) + status.substring(1).toLowerCase()}</span>;
                            }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeaveTable