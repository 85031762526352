import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useStoreState } from 'easy-peasy';
import { Toast } from 'primereact/toast';
import constants from '../../constants/constants';
import getHeaders from '../../constants/utils';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';
import { Divider } from "primereact/divider";
import { Dialog } from 'primereact/dialog';

const EmailConfigSetting = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [configScreen, setConfigScreen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [configErrorMsg, setConfigErrorMsg] = useState(false);
    const [userList, setUserList] = useState();
    const [configData, setConfigData] = useState();
    const user = useStoreState((actions) => actions.loginModel.user);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        setIsLoading(true);
        getEmailConfig();
        getEmployeeList();
    }, []);

    const getEmployeeList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.EMPLOYEE_LIST, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setUserList(resp.data.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getEmailConfig = () => {
        axios.get(constants.URL.EMAIL_CONFIG + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                const data = resp?.data?.results;
                setValue("from_mail", data?.from_mail)
                setValue('app_password', data?.app_password)
                setValue('display_name', data?.display_name)
                setValue('services', data?.services)
                setValue('cc', data?.cc)
                setConfigData(data);
                setConfigErrorMsg(data?.status === "Failed" ? true : false);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const defaultValues = { from_mail: "", app_password: "", display_name: "", services: "", cc: [] };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (values) => {
        const data = {
            org_id: user?.org_id?._id,
            from_mail: values.from_mail,
            app_password: values.app_password,
            display_name: values.display_name,
            services: values.services,
            cc: values.cc,
            email_domain: user?.org_id?.domains[0],
            update: configData ? "Update" : "Save"
        };

        setIsLoading(true);
            axios
                .post(constants.URL.EMAIL_CONFIG, data, {
                    headers: getHeaders(),
                })
                .then((resp) => {
                    toast.current.show({ severity: "success", summary: "Success", detail: "Email Configured Successfully" });
                    getEmailConfig();
                    setConfigScreen(false);
                })
                .catch((e) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: e.response.data.message });
                    console.error(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    const onSubmitReset = (values) => {
        const data = {
            org_id: user?.org_id?._id,
            from_mail: values.from_mail,
            app_password: values.app_password,
            display_name: values.display_name,
            services: values.services,
            cc: values.cc,
            email_domain: user?.org_id?.domains[0]
        };

        setIsLoading(true);
            axios
                .post(constants.URL.EMAIL_CONFIG, data, {
                    headers: getHeaders(),
                })
                .then((resp) => {
                    toast.current.show({ severity: "success", summary: "Success", detail: "Password Changed Successfully" });
                    getEmailConfig();
                    setConfigScreen(false);
                    setVisible(false);
                })
                .catch((e) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: e.response.data.message });
                    console.error(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="card mt-3">
                {configData && !configScreen ?
                <div>
                    {configData?.status === "Failed" && configErrorMsg ?
                    <div className='card flex justify-content-between align-items-center'>
                        <span>Error sending email notification. Please check your Email Configuration Settings.<br />
                        <small>Once the configuration is updated and an email is successfully sent, this message will clear.</small></span><i className='pi pi-times' onClick={()=> setConfigErrorMsg(false)} style={{ color: 'var(--primary-color)' }} />
                    </div> : null}
                    <div className="blue_bg px-4 py-3 relative mb-3">
                        <h6 className="blue_bg_heading mb-0">Details</h6>
                        <div className="iplus">
                            <i className="pi pi-pencil" onClick={() => setConfigScreen(true)} style={{ fontSize: "1.2rem" }}></i>
                        </div>
                    </div>
                        <div>
                            <div className="flex align-items-center ml-3">
                                <span className='col-4 font-medium'>Email Id</span>
                                <span className='col-4'><InputText value={configData?.from_mail} className='border-none w-full' disabled /></span>
                                <span className='col-4 text-500'>Required Email ID for updates</span>
                            </div>
                            <Divider className='my-2' />
                            <div className="flex align-items-center ml-3">
                                <span className='col-4 font-medium'>App Password</span>
                                <span className='col-4'><InputText value="..........." className='border-none w-full text-4xl' disabled /></span>
                                <span className='col-4 text-500'>Security purpose for given</span>
                            </div>
                            <Divider className='my-2' />
                            <div className="flex align-items-center ml-3">
                                <span className='col-4 font-medium'>Display Name</span>
                                <span className='col-4'><InputText value={configData?.display_name} className='border-none w-full' disabled /></span>
                                <span className='col-4 text-500'>Represent name in messages</span>
                            </div>
                            <Divider className='my-2' />
                            <div className="flex align-items-center ml-3">
                                <span className='col-4 font-medium'>CC</span>
                                <span className='col-4'><InputText value={configData?.cc} className='border-none w-full' disabled /></span>
                                <span className='col-4 text-500'>Reporting people Email ID</span>
                            </div>
                            <Divider className='my-2' />
                            <div className="flex align-items-center ml-3">
                                <span className='col-4 font-medium'>Services</span>
                                <span className='col-4'><InputText value={configData?.services} className='border-none w-full' disabled /></span>
                                <span className='col-4 text-500'>Service basic Info</span>
                            </div>
                        </div>
                </div>
                :
                <form className="error_msg">
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-4">
                            <label htmlFor="from_mail">
                                Email<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="from_mail"
                                control={form.control}
                                rules={{ required: "Email is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="app_password">
                                App Password<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="app_password"
                                control={form.control}
                                rules={{ required: "App Password is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText id={field.name} disabled={configData} value={configData ? ".........." : field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} placeholder='No.of.Continuous' />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="display_name">
                                Display Name<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="display_name"
                                control={form.control}
                                rules={{ required: "Display Name is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="services">
                                Services<span className="p-error">*</span>
                            </label>
                            <Controller
                                name="services"
                                control={form.control}
                                rules={{ required: "Services is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Dropdown options={["Gmail", "Microsoft"]} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="cc">
                                CC<span className="p-error">*</span>
                            </label>
                            <span className='min-w-full'>
                            <Controller
                                name="cc"
                                control={form.control}
                                // rules={{ required: "CC is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <MultiSelect display="chip" filter options={userList} optionLabel='first_name' optionValue='email' id={field.name} value={field.value} className={classNames("w-full",{ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-content-end gap-2">
                        <Button onClick={()=> setVisible(true)} size="small" loading={isLoading} label="Reset Password" className={configData ? "" : "hidden"} />
                        <Button onClick={form.handleSubmit(onSubmit)} size="small" loading={isLoading} label={configData ? "Update" : "Save"} />
                    </div>
                </form>
                }
                <Dialog style={{ width: "35vw" }} breakpoints={{ '960px': '60vw', '641px': '80vw' }} header="Reset Password" visible={visible} onHide={() => { setVisible(false) }}>
                <form className="dialog-form">
                    <div className="field col-12 mb-0 pb-0 pl-0">
                        <label className="col-4" htmlFor="app_password">
                            New App Password<span className="p-star">*</span>
                        </label>
                        <span className="col-8 p-0 relative">
                            <Controller
                                name="app_password"
                                control={form.control}
                                rules={{
                                    required: "App Password is required."
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText type={showPassword ? "text" : "password"} id={field.name} className={classNames({ "p-invalid": fieldState.error }, "w-full")} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                                {showPassword ? (
                                    <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                ) : (
                                    <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                )}
                            </span>
                            {/* </span> */}
                            {getFormErrorMessage("app_password")}</span>
                    </div>
                    <div className='flex align-items-center justify-content-end gap-4 mb-3 mr-2'>
                        <Button onClick={form.handleSubmit(onSubmitReset)} className='add-btn cursor-pointer border-round-sm' label='Save'></Button>
                    </div>
                </form>
            </Dialog>
            </div>
        </div>
    )
}

export default EmailConfigSetting