/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Button } from "primereact/button";
import LeaveTable from "../components/LeaveTable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";
import constants from "../constants/constants";
import { Toast } from "primereact/toast";
import getHeaders from "../constants/utils";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";

const Leave = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleCompensation, setVisibleCompensation] = useState(false);
    const setLeaveList = useStoreActions((actions) => actions.leaveModel.setLeaveList);
    const user = useStoreState((state) => state.loginModel.user);
    const [months, setMonths] = useState(null);
    const [years, setYears] = useState(null);
    const [leaveTypeList, setLeaveTypeList] = useState(null);
    const today = new Date();
    const disabledDays = [0, 6]; 

    const employeeDetails = useStoreState((actions) => actions.employeeModel.employeeDetails);
    let ManagerAuto = employeeDetails?.find((item) => item._id === user?._idx);

    useEffect(() => {
        setLeaveList();
        setIsLoading(true);
        getAllowedLeaveTypes();
        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        const currentYear = date.getFullYear();
        const monthFind = month.find((item) => {return item?.code == currentMonth});
        const findYear = year.find((item) => {return item?.name == currentYear});
        setMonths(monthFind?.code);
        setYears(findYear?.code);
    }, []);

    const getAllowedLeaveTypes = () => {
        axios.get(constants.URL.ALLOWED_LEAVE_TYPES + user?._id + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                const result = resp?.data?.results?.filter((item) => { return (item.allowedLeaves && (item.allowedLeaves !== 0)) ? item : null });
                // console.log(result);
                const data = [...result]
                const lop = {
                    category: "LOP",
                    type: "Loss Of Pay"
                }
                data.push(lop)
                setLeaveTypeList(data);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const sessionOption = [
        // { label: "FullDay", value: "FullDay" },
        { label: "BeforeNoon", value: "BeforeNoon" },
        { label: "AfterNoon", value: "AfterNoon" },
    ];

    useEffect(async () => {
        if (months && years) {
            getLeaves(months, years);
        }
    }, [months, years]);

    const getLeaves = (months, years) => {
        setIsLoading(true);
        axios
            .get(constants.URL.LEAVE_LIST + user._id + "?month=" + months + "&year=" + years, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setLeaveList(resp.data.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const countWeekdays = (fromDate, toDate) => {
        let count = 0;
        const currentDate = new Date(fromDate);
      
        while (currentDate <= toDate) {
          const dayOfWeek = currentDate.getDay();
          if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude Sundays (0) and Saturdays (6)
            count++;
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
      
        return count;
    };

    const onSubmit = (values) => {
        const data = {
            userName: user?.name,
            user_id: user?._id,
            org_id: user?.org_id?._id,
            from_date: formatDate(values.from),
            to_date: formatDate(values.to),
            type: values.type.category === "LOP" ? values.type.category : values.type.type,
            // session: values.session,
            manager: ManagerAuto?._id,
            reason: values.reason,
            configID: values.type._id,
            from_session: values?.fromSession,
            to_session: values?.toSession
        };
        console.log(data);
        const fromDate = new Date(values.from);
        const toDate = new Date(values.to);
        // const daysBetween = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24)) + 1; // +1 to include the start date
        // console.log(daysBetween);
        // const maxmin = values?.type?.maxmin;
        // if (maxmin == "max" && (daysBetween > values?.type?.restrictions)) {
        //     return toast.current.show({ severity: "error", summary: "Error", detail: `You cannot apply for more than ${values?.type?.restrictions} days at a time` });
        // } else if (maxmin == "min" && (daysBetween < values?.type?.restrictions)) {
        //     return toast.current.show({ severity: "error", summary: "Error", detail: `You cannot apply for less than ${values?.type?.restrictions} days at a time` });
        // }
        // console.log(daysBetween);

        setIsLoading(true);
        axios
            .post(constants.URL.APPLY_LEAVE, data, { headers: getHeaders() })
            .then((resp) => {
                toast.current.show({ severity: "success", summary: "Success", detail: "Applied Leave Successfully" });
                setVisible(false);
                reset();
                const currentMonth = fromDate.getMonth() + 1;
                const currentYear = fromDate.getFullYear();
                const monthFind = month.find((item) => {return item?.code == currentMonth});
                const findYear = year.find((item) => {return item?.name == currentYear});
                setMonths(monthFind?.code);
                setYears(findYear?.code);
                getLeaves(currentMonth, currentYear);
                setFromDate(null);
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error", detail: e?.response?.data?.message });
                console.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onSubmitCompensation = (values) => {
        const data = {
            userName: user?.name,
            user_id: user?._id,
            from_date: formatDate(values.from),
            to_date: formatDate(values.from),
            manager: ManagerAuto?._id,
            org_id: user?.org_id?._id,
            reason: values.reason
        };
        // console.log(data);

        setIsLoading(true);
        axios
            .post(constants.URL.APPLY_LEAVE_COMPENSATION, data, { headers: getHeaders() })
            .then((resp) => {
                toast.current.show({ severity: "success", summary: "Success", detail: "Applied Leave Compensation Successfully" });
                setVisibleCompensation(false);
                reset();
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error", detail: e?.response?.data?.message });
                console.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const month = [
        { name: "Jan", code: "1" },
        { name: "Feb", code: "2" },
        { name: "March", code: "3" },
        { name: "April", code: "4" },
        { name: "May", code: "5" },
        { name: "June", code: "6" },
        { name: "July", code: "7" },
        { name: "Aug", code: "8" },
        { name: "Sep", code: "9" },
        { name: "Oct", code: "10" },
        { name: "Nov", code: "11" },
        { name: "Dec", code: "12" },
    ];
    const year = [
        { name: "2022", code: "2022" },
        { name: "2023", code: "2023" },
        { name: "2024", code: "2024" },
        { name: "2025", code: "2025" },
        { name: "2026", code: "2026" },
    ];

    // const [filteredautokeyprocess, setFilteredautokeyprocess] = useState(null);
    // const keyautocompletesss = employeeDetails;

    // const searchAutoClassification = (event) => {
    //     setTimeout(() => {
    //         let _filteredautokeyprocess;
    //         if (!event.query.trim().length) {
    //             _filteredautokeyprocess = [...keyautocompletesss];
    //         } else {
    //             _filteredautokeyprocess = keyautocompletesss.filter((keyautocomplete) => {
    //                 return keyautocomplete.first_name.toLowerCase().startsWith(event.query.toLowerCase());
    //             });
    //         }

    //         setFilteredautokeyprocess(_filteredautokeyprocess);
    //     }, 250);
    // };
    // console.log(filteredautokeyprocess);

    const defaultValues = { date: "", month: "", year: "", workdays: "", lop: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const leaveTypeTemplate = (option) => {
        return (
            <div className="flex flex-column">
                <div>{option.category}</div>
                <div>{option.type}</div>
            </div>
        );
    };

    const formatDate = (value) => {
        return value?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    const [fromDate, setFromDate] = useState(null);

    return (
        <div>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12 mt-3">
                    <div className="card p-fluid rightContent-card pt-3">
                        <div className="md:flex flex-wrap align-items-center justify-content-between mb-3">
                            <h6 className="blue_bg_heading md:mb-0">Leave History</h6>
                            <div className="md:inline-flex flex flex-wrap align-items-center justify-content-center">
                                <Dropdown value={months} onChange={(e) => setMonths(e.target.value)} options={month} optionLabel="name" optionValue="code" placeholder="Month" className="w-8rem m-1" />
                                <Dropdown value={years} onChange={(e) => setYears(e.target.value)} options={year} optionLabel="name" optionValue="name" placeholder="Year" className="w-8rem m-1" />
                                <div className="m-1">
                                <Button icon="pi pi-plus" iconPos="right" label="Compensation" onClick={() => setVisibleCompensation(true)} size="small" /></div>
                                <div className="m-1"><Button icon="pi pi-plus" iconPos="right" label="New Leave" onClick={() => { setVisible(true); getAllowedLeaveTypes() }} /></div>
                            </div>
                        </div>
                        <LeaveTable />
                            <Dialog header="Leave Application" visible={visible} onHide={() => {setVisible(false); reset(); setFromDate(null)}} style={{ width: "40vw" }} breakpoints={{ '960px': '60vw', '641px': '80vw' }}>
                                <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                                    <div className="formgrid grid">
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="name2">
                                                From<span className="p-error">*</span>
                                            </label>
                                            <Controller
                                                name="from"
                                                control={form.control}
                                                rules={{ required: "From date is required." }}
                                                render={({ field, fieldState }) => <Calendar disabledDays={disabledDays} name="from" minDate={today} dateFormat="mm/dd/yy" value={field.value} onChange={(e) => {field.onChange(e.target.value); setFromDate(e.target.value); setValue("to", "")}} showIcon className={classNames({ "p-invalid w-full": fieldState.error })} />}
                                            />
                                            <span className="mb-1"></span>
                                            <Controller
                                                name="fromSession"
                                                control={form.control}
                                                rules={{ required: "From Session is required." }}
                                                render={({ field, fieldState }) => (
                                            <Dropdown value={field.value} options={sessionOption} name="fromSession" control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} placeholder="Select a session" />
                                        )}
                                        />
                                        {errors.from ? getFormErrorMessage("from") : errors.fromSession ? getFormErrorMessage("fromSession") : getFormErrorMessage('')}
                                        </div>
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="email2">
                                                To<span className="p-error">*</span>
                                            </label>
                                            <Controller
                                                name="to"
                                                control={form.control}
                                                rules={{ required: "To date is required." }}
                                                render={({ field, fieldState }) => <Calendar disabledDays={disabledDays} name="to" minDate={fromDate} dateFormat="mm/dd/yy" value={field.value} onChange={(e) => field.onChange(e.target.value)} showIcon className={classNames({ "p-invalid w-full": fieldState.error })} />}
                                            />
                                            <span className="mb-1"></span>
                                            <Controller
                                                name="toSession"
                                                control={form.control}
                                                rules={{ required: "To Session is required." }}
                                                render={({ field, fieldState }) => (
                                            <Dropdown value={field.value} options={sessionOption} name="toSession" control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} placeholder="Select a session" />
                                        )}
                                        />
                                        {errors.from ? getFormErrorMessage("to") : errors.fromSession ? getFormErrorMessage("toSession") : getFormErrorMessage('')}
                                        </div>

                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="state">
                                                Leave Type<span className="p-error">*</span>
                                            </label>
                                            <Controller
                                                name="type"
                                                control={form.control}
                                                rules={{ required: "Type is required." }}
                                                render={({ field, fieldState }) => (
                                                    <Dropdown value={field.value} placeholder="Select a Type" name="type" options={leaveTypeList} optionLabel="type" itemTemplate={leaveTypeTemplate} control={form.control} onChange={(e) => field.onChange(e.value)} className={classNames({ "p-invalid w-full": fieldState.error })} />
                                                )}
                                            />
                                            {getFormErrorMessage("type")}
                                        </div>
                                        <div className="field col-12 md:col-6 pb-4">
                                            <label htmlFor="state">
                                                Manager<span className="p-error">*</span>
                                            </label>
                                            <InputText id="state" defaultValue={ManagerAuto?.first_name} readOnly className="w-full"></InputText>
                                        </div>
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="state">
                                                Leave Reason<span className="p-error">*</span>
                                            </label>
                                            <Controller
                                                name="reason"
                                                control={form.control}
                                                rules={{ required: "Reason is required." }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} />

                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-content-end">
                                        <Button icon="pi pi-check" size="small" loading={isLoading} label="Apply" />
                                    </div>
                                </form>
                            </Dialog>
                            <Dialog header="Compensation Application" visible={visibleCompensation} onHide={() => {setVisibleCompensation(false); reset()}} style={{ width: "40vw" }} breakpoints={{ '960px': '60vw', '641px': '80vw' }}>
                                <form onSubmit={form.handleSubmit(onSubmitCompensation)} className="error_msg">
                                    <div className="formgrid grid ">
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="name2">
                                                Date<span className="p-error">*</span>
                                            </label>
                                            <Controller
                                                name="from"
                                                control={form.control}
                                                rules={{ required: "From date is required." }}
                                                render={({ field, fieldState }) => <Calendar name="from" dateFormat="mm/dd/yy" value={field.value} onChange={(e) => field.onChange(e.target.value)} showIcon className={classNames({ "p-invalid w-full": fieldState.error })} />}
                                            />
                                            {getFormErrorMessage("from")}
                                        </div>
                                        {/* <div className="field col-12 md:col-6">
                                            <label htmlFor="email2">
                                                To<span className="p-error">*</span>
                                            </label>
                                            <Controller
                                                name="to"
                                                control={form.control}
                                                rules={{ required: "To date is required." }}
                                                render={({ field, fieldState }) => <Calendar name="to" minDate={today} dateFormat="mm/dd/yy" value={field.value} onChange={(e) => field.onChange(e.target.value)} showIcon className={classNames({ "p-invalid w-full": fieldState.error })} />}
                                            />
                                            {getFormErrorMessage("to")}
                                        </div> */}

                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="state">
                                                Leave Reason<span className="p-error">*</span>
                                            </label>
                                            <Controller
                                                name="reason"
                                                control={form.control}
                                                rules={{ required: "Reason is required." }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error, "w-full": true })} onChange={(e) => field.onChange(e.target.value)} />

                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="field col-12 md:col-6 pb-4">
                                            <label htmlFor="state">
                                                Manager<span className="p-error">*</span>
                                            </label>
                                            <InputText id="state" defaultValue={ManagerAuto?.first_name} readOnly className="w-full"></InputText>
                                        </div>
                                    </div>
                                    <div className="flex justify-content-end">
                                        <Button icon="pi pi-check" size="small" loading={isLoading} label="Apply" />
                                    </div>
                                </form>
                            </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Leave;
